const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/SelectContract.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";
import dayjs from "dayjs";
import { Select } from "antd";


export const SelectContract = (
  props



) => {
  const { value, setValue, fieldname, parseObject } = props;
  const [contracts, setContracts] = React.useState([]);

  React.useEffect(() => {
    initContracts();
  }, []);

  const initContracts = async () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.include("User");
    let results = await query.find();
    setContracts(results);
  };

  return (
    React.createElement(Select, {
      value: _optionalChain([value, 'optionalAccess', _ => _.id]),
      onChange: (e) => {
        const target = contracts.find((obj) => obj.id === e);
        setValue(target);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}

      , contracts.map((contract) => (
        React.createElement(Select.Option, { key: contract.id, value: contract.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          , contract.get("User").get("name"), " " , " (", " "
          , dayjs(contract.get("Start")).format("DD.MM.YYYY"), " " , " - ", " "
          , dayjs(contract.get("End")).format("DD.MM.YYYY"), " " , ")"
        )
      ))
    )
  );
};
