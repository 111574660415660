const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/SelectAbrufart.tsx";import React from "react";
import { Select } from "antd";


export const SelectAbrufart = (
  props



) => {
  const { value, setValue, fieldname, parseObject } = props;

  return (
    React.createElement(Select, {
      value: value,
      onChange: (e) => {
        setValue(e);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}

      , React.createElement(Select.Option, { key: 1, value: "Mittelabruf", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "Mittelabruf"

      )
      , React.createElement(Select.Option, { key: 2, value: "Zwischennachweis", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "Zwischennachweis"

      )
      , React.createElement(Select.Option, { key: 3, value: "Korrektur", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Korrektur"

      )
    )
  );
};
