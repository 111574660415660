const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/ProjectTrip.tsx";import React from "react";
import dayjs from "dayjs";

import { Table } from "antd";






export const ProjectTrip = ({ dataSource }) => {
  let columns = [
    {
      title: "Start",
      align: "center",
      dataIndex: "start",
      key: "start",
      render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, dayjs(text).format("DD.MM.YYYY")),
    },
    {
      title: "Ende",
      align: "center",
      dataIndex: "end",
      key: "end",
      render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, dayjs(text).format("DD.MM.YYYY")),
    },
    {
      title: "Mitarbeiter",
      align: "center",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Beschreibung",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Kosten",
      align: "center",
      dataIndex: "costs",
      key: "costs",
      render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, text, " €" ),
    },
    {
      title: "Belege",
      align: "center",
      dataIndex: "documents",
      key: "documents",
      render: (text) => (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
          , React.createElement('a', { href: text, target: "_blank", rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Download"

          )
        )
      ),
    },
  ];

  const [tableColumns, setTableColumns] =
    React.useState(columns);

  React.useEffect(() => {
    setTableColumns(columns);
  }, []);

  return (
    React.createElement(Table, {
      size: "small",
      columns: tableColumns,
      dataSource: dataSource,
      pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
    )
  );
};
