 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Parse from "parse";

import { TimeComponent } from "./components/TimeComponent";
import { HolidayComponent } from "./components/AdminPages/HolidayComponent";
import { VacationComponent } from "./components/VacationComponent";
import { VacationCheckComponent } from "./components/AdminPages/VacationCheckComponent";
import { $parse } from "@opendash/plugin-parse";
import { ContractComponent } from "./components/AdminPages/ContractComponent";





export class TimeTrackingPlugin  {
    __init() {this.name = "TimeTrackingPlugin"}

     __init2() {this.options = {}}

  constructor(options) {;TimeTrackingPlugin.prototype.__init.call(this);TimeTrackingPlugin.prototype.__init2.call(this);
    this.options = options || {};
  }
    
    async onFactory(factory) {
        factory.registerRoute({
          path: "/timetracking/time",
          props: this.options,
          component: async () => ({ default: TimeComponent }),
          //permission: "",
        });

        factory.registerRoute({
          path: "/timetracking/vacation",
          props: this.options,
          component: async () => ({ default: VacationComponent }),
          //permission: "",
        });

        factory.registerRoute({
          path: "/admin/timetracking/holiday",
          props: this.options,
          component: async () => ({ default: HolidayComponent }),
          permission: "parse-admin",
        });

        factory.registerRoute({
          path: "/admin/timetracking/vacation",
          props: this.options,
          component: async () => ({ default: VacationCheckComponent }),
          permission: "parse-admin",
        });
    
        factory.registerRoute({
          path: "/admin/parse/contract",
          props: this.options,
          component: async () => ({ default: ContractComponent }),
          permission: "parse-admin",
        });

        // Navigation:
        factory.registerStaticNavigationItem({
          id: "timetracking/time",
          place: "frontpage",
          group: "timetracking",
          order: 11,
          color: "#676767",
          label: "Zeiterfassung",
          icon: "fa:clock",
          link: "/timetracking/time",
          routeCondition: "**",
          activeCondition: "/timetracking",
        });

        factory.registerStaticNavigationItem({
          id: "timetracking/vacation",
          place: "frontpage",
          group: "vacation",
          order: 11,
          color: "#676767",
          label: "Urlaubsverwaltung",
          icon: "fa:suitcase",
          link: "/timetracking/vacation",
          routeCondition: "**",
          activeCondition: "/timetracking",
          permission: "vacation:can-use",
        });

        factory.registerStaticNavigationGroup({
          label: "Zeiterfassung",
          order: 30,
          id: "admin/timetracking",
        });

        factory.registerStaticNavigationItem({
          id: "admin/vacationtracking",
          group: "admin/timetracking",
          place: "sidebar",
          order: 10,
          label: "Urlaubsverwaltung",
          icon: "fa:suitcase",
          link: "/admin/timetracking/vacation",
          routeCondition: "/admin/**",
          activeCondition: "/admin/timetracking/vacation",
          permission: "parse-admin",
        });
        factory.registerStaticNavigationItem({
          id: "admin/timetrackingItem",
          group: "admin/timetracking",
          place: "sidebar",
          order: 20,
          label: "Feiertage",
          icon: "fa:umbrella-beach",
          link: "/admin/timetracking/holiday",
          routeCondition: "/admin/**",
          activeCondition: "/admin/timetracking/holiday",
          permission: "parse-admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/contractadmin",
          group: "admin/parse",
          place: "sidebar",
          order: 90,
          label: "Verträge",
          icon: "fa:file-contract",
          link: "/admin/parse/contract",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/contract",
          permission: "parse-admin",
        });

        $parse.ui.setClassConfig({
          className: "OD3_TimeTrackingBlocked",
          disableACLEditing: true,
          titleFields: ["Reason"],
          displayFields: ["Date", "Reason"],
          createFields: ["Date", "Reason"],
          editFields: ["Date", "Reason"],
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _ => _.user, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant"), 'optionalAccess', _6 => _6.id]);
            if (tenantId) {
              acl["role:od-tenant-user-" + tenantId] = {
                read: true,
                write: false,
              };
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_Contract",
          disableACLEditing: true,
          titleFields: ["User.name"],
          displayFields: ["Start", "End", "HoursADay", "VacationDays", "User", "Status"],
          createFields: ["Start", "End", "HoursADay", "VacationDays", "User", "Status"],
          editFields: ["Start", "End", "HoursADay", "VacationDays", "User", "Status"],
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _7 => _7.user, 'access', _8 => _8.current, 'call', _9 => _9(), 'optionalAccess', _10 => _10.get, 'call', _11 => _11("tenant"), 'optionalAccess', _12 => _12.id]);
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
           afterCreate: async (classname, objectId) => {
              const query = new Parse.Query(classname);
              query.equalTo("objectId", objectId);
              query.include("User");
              const obj = await query.get(objectId);
              const user = obj.get("User");
              const acl = obj.getACL() || new Parse.ACL();
              acl.setReadAccess(user, true);  
              acl.setWriteAccess(user, false);
              obj.setACL(acl);
              await obj.save();
          }
        });

        // Translations:
        factory.registerTranslationResolver(
          "en",
          "timetracking",
          async () => await import("./translations/en.json")
        );
    
        factory.registerTranslationResolver(
          "de",
          "timetracking",
          async () => await import("./translations/de.json")
        );
      }
    }