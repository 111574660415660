const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/ProjectInvest.tsx";import React from "react";
import dayjs from "dayjs";

import { Table } from "antd";







export const ProjectInvest = ({
  dataSource,
  invest,
}) => {
  let columns = [
    {
      title: "Bezeichnung",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Beschreibung",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Nettokosten",
      align: "center",
      dataIndex: "netto",
      key: "netto",
    },
    {
      title: "Rechnungsdatum",
      align: "center",
      dataIndex: "invoicedate",
      key: "invoicedate",
      render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, dayjs(text).format("DD.MM.YYYY")),
    },
  ];

  if (invest) {
    columns.push(
      {
        title: "Laufzeit",
        align: "center",
        dataIndex: "duration",
        key: "duration",
        render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, text, " Monate" ),
      },

      {
        title: "Abschreibungskosten",
        align: "center",
        dataIndex: "monthcosts",
        key: "monthcosts",
        render: (text) => React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, text, " €/Monat" ),
      },
      {
        title: "Rechnung",
        align: "center",
        dataIndex: "invoice",
        key: "invoice",
        render: (text) => (
          React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , React.createElement('a', { href: text, target: "_blank", rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "Download"

            )
          )
        ),
      }
    );
  } else {
    columns.push({
      title: "Rechnung",
      align: "center",
      dataIndex: "invoice",
      key: "invoice",
      render: (text) => (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
          , React.createElement('a', { href: text, target: "_blank", rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "Download"

          )
        )
      ),
    });
  }

  const [tableColumns, setTableColumns] =
    React.useState(columns);

  React.useEffect(() => {
    setTableColumns(columns);
  }, []);

  return (
    React.createElement(Table, {
      size: "small",
      columns: tableColumns,
      dataSource: dataSource,
      pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
    )
  );
};
