const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/Pieces/VacationModal.tsx";import React from "react";
import dayjs from "dayjs";

import { Modal, DatePicker, Alert, Space, message } from "antd";

const { RangePicker } = DatePicker;








export const VacationModal = ({
  modal,
  setModalVisibility,
  addVacationApplication,
  anzahlWerktagen,
}) => {
  const [startEndDates, setStartEndDates] = React.useState

([dayjs(), dayjs().add(1, "day")]);

  return (
    React.createElement(Modal, {
      title: "Neuen Urlaubsantrag",
      open: modal,
      onOk: () => {
        addVacationApplication(
          startEndDates[0].toDate(),
          startEndDates[1].toDate(),
          "Erholungsurlaub",
          startEndDates[0],
          startEndDates[1]
        )
          ? setModalVisibility(false)
          : message.error("Es dürfen keine Zeiteinträge in den Tagen sein!");
      },
      okText: "Beantragen",
      onCancel: () => {
        setModalVisibility(false);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}

      , React.createElement(Space, { direction: "vertical", size: "middle", style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , React.createElement(Alert, {
          message: 
            "Entspricht " +
            anzahlWerktagen(startEndDates[0], startEndDates[1]) +
            " Urlaubstage"
          ,
          type: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        )
        , React.createElement(RangePicker, {
          value: startEndDates,
          onChange: ([start, end]) => {
            setStartEndDates([start || dayjs(), end || dayjs().add(1, "day")]);
          },
          style: { width: "100%" },
          format: "DD.MM.YYYY", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
      )
    )
  );
};
