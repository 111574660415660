const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/Pieces/AlertView.tsx";import React from "react";
import dayjs from "dayjs";
import { Alert, Space } from "antd";














export const AlertView = ({
  contract,
  isMobile,
  totalWorked,
  shouldWorked,
  totalProjectWorked,
  maxProjectWorked,
  selectedMonth,
  selectedYear,
  daysEntered,
  anzahlWerktagen,
}) => {
  return (
    React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      , contract && isMobile && (
        React.createElement(Alert, {
          style: { textAlign: "center" },
          message: 
            "Eingetragene Stunden: " +
            totalWorked +
            "/" +
            shouldWorked +
            " Stunden (Projekt: " +
            Math.round(totalProjectWorked * 100) / 100 +
            "/" +
            Math.round(maxProjectWorked * 100) / 100 +
            " Stunden)"
          ,
          type: 
            totalProjectWorked > maxProjectWorked
              ? "error"
              : totalWorked > shouldWorked
              ? "error"
              : "success"
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        )
      )
      , contract && totalWorked == 0 && (
        React.createElement(Alert, {
          style: { textAlign: "center" },
          message: "Es wurden diesen Monat noch keine Einträge gemacht."       ,
          type: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        )
      )
      , contract &&
        (dayjs().month() !== selectedMonth ||
          dayjs().year() !== selectedYear) &&
        anzahlWerktagen(selectedMonth + 1, selectedYear) > daysEntered &&
        totalWorked > 0 && (
          React.createElement(Alert, {
            style: { textAlign: "center" },
            message: "Monat noch nicht vollständig. Bitte tragen Sie die restlichen Tage ein."          ,
            type: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          )
        )
      , contract && totalWorked > shouldWorked && (
        React.createElement(Alert, {
          style: { textAlign: "center" },
          message: "Es wurden zuviele Stunden aufgeschrieben, bitte korrigieren und über Gleitzeit ausgleichen!"          ,
          type: "warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )
      )
      , contract && totalProjectWorked > maxProjectWorked && (
        React.createElement(Alert, {
          style: { textAlign: "center" },
          message: 
            "Es wurden zuviele Projektstunden eingetragen. Bitte die Projektstunden korrigieren! (Max: " +
            maxProjectWorked +
            " Stunden)"
          ,
          type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        )
      )
      , !contract && (
        React.createElement(Alert, {
          style: { textAlign: "center" },
          message: 
            "Es ist kein Vertrag für diesen Monat vorhanden! Es können keine Stunden eingetragen werden!"
          ,
          type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        )
      )
    )
  );
};
