const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/MultiTimeLine.tsx";import React from "react";
import dayjs from "dayjs";













export const MultiTimeline = ({
  start,
  end,
  today,
  events,
}) => {
  React.useEffect(() => {
    initTimeLine();
  }, [start, end, today]);

  const initTimeLine = () => {
    const startDate = start;
    const endDate = end;

    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.ceil(
      ((today ) - startDate) / (1000 * 60 * 60 * 24)
    );
  };

  const calculateEventPosition = (eventDate) => {
    const startDate = start;
    const endDate = end;
    const eventDays = Math.ceil(
      (eventDate.getTime() - startDate) / (1000 * 60 * 60 * 24)
    );
    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    return `${(eventDays / totalDays) * 100}%`;
  };

  const calculateEventDiff = (eventDate) => {
    const startDate = start;
    const diffYears = startDate.getFullYear() - eventDate.getFullYear();
    const diffMonths = startDate.getMonth() - eventDate.getMonth();

    // Berechne die Anzahl der Monate
    const totalMonths = diffYears * 12 + diffMonths;
    return totalMonths * -1;
  };

  let topPosition = ["15%", "27%", "39%"];

  return (
    React.createElement('div', {
      style: {
        position: "relative",
        width: "100%",
        height: "400px",
        overflow: "hidden",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}

      /* Startdatum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "0%",
          transform: "translateY(-50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "70%",
          left: "0%",
          transform: "translateX(0%)",
          textAlign: "left",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, dayjs(start).format("DD.MM.YYYY"))
      )

      /* Enddatum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "100%",
          transform: "translateX(-100%) translateY(-50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "70%",
          left: "100%",
          transform: "translateX(-100%)",
          textAlign: "right",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}, dayjs(end).format("DD.MM.YYYY"))
      )

      /* Zeitstrahl */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translateY(-50%)",
          width: "100%",
          height: "3px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
)

      /* Events */
      , events.map((event, index) => {
        const eventPosition = calculateEventPosition(event.endDate);
        const eventDiff = calculateEventDiff(event.endDate);

        return (
          React.createElement('div', { key: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
            , React.createElement('div', {
              style: {
                position: "absolute",
                top: "50%",
                left: eventPosition,
                transform: "translate(-50%, -50%)",
                width: "4px",
                height: "20px",
                backgroundColor: "#aaa",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
)
            , React.createElement('div', {
              style: {
                position: "absolute",
                top: topPosition[index % 3],
                left: eventPosition,
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}

              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
                , event.name, " " , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} )
                , React.createElement('div', {
                  style: {
                    fontSize: "12px",
                    color:
                      eventDiff > 18
                        ? "#31ad4a"
                        : eventDiff < 12
                        ? "#eb4034"
                        : "#e8ed4e",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
, "("
                  , eventDiff + " Monate", ")"
                )
              )
            )
          )
        );
      })

      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
        , React.createElement('div', {
          style: {
            position: "absolute",
            top: "50%",
            right: -2,
            transform: "translate(-50%, -50%)",
            width: "4px",
            height: "30px",
            backgroundColor: "#2db7f5",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}
)
        , React.createElement('div', {
          style: {
            position: "absolute",
            top: "70%",
            right: -30,
            transform: "translate(-50%, -50%)",
            textAlign: "right",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}

          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}, "+3 Jahre" )
          )
        )
      )

      /* Aktueller Tag */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: 2,
          transform: "translate(-50%, -50%)",
          width: "4px",
          height: "30px",
          backgroundColor: "#2db7f5",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}
)

      /* Heutiges Datum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "70%",
          left: 20,
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}

        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 229}}, "Heute")
        )
      )
    )
  );
};
