 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Parse from "parse";

import { ProjectComponent } from "./components/AdminPages/ProjectComponent";
import { $parse, } from "@opendash/plugin-parse";
import { ProjectRequestComponent } from "./components/ProjectRequestComponent";
import { ProjectInvoiceComponent } from "./components/ProjectInvoiceComponent";
import { ProjectTripComponent } from "./components/ProjectTripComponent";
import { SelectPosition } from "./components/Pieces/SelectPosition";
import { ProjectOverviewComponent } from "./components/ProjectOverviewComponent";
import { MoneyCallComponent } from "./components/AdminPages/MoneyCallComponent";
import { SelectAbrufart } from "./components/Pieces/SelectAbrufart";
import { ProjectContractComponent } from "./components/AdminPages/ProjectContractComponent";
import { SelectContract } from "./components/Pieces/SelectContract";




export class ResearchProjectPlugin  {
    __init() {this.name = "ResearchProjectPlugin"}

     __init2() {this.options = {
      
    }}

  constructor(options) {;ResearchProjectPlugin.prototype.__init.call(this);ResearchProjectPlugin.prototype.__init2.call(this);
    this.options = options || {};
  }

    async onFactory(factory) {
        factory.registerRoute({
          path: "/admin/parse/projects",
          props: this.options,
          component: async () => ({ default: ProjectComponent }),
          permission: "parse-admin",
        });

        factory.registerRoute({
          path: "/admin/parse/moneycalls",
          props: this.options,
          component: async () => ({ default: MoneyCallComponent }),
          permission: "parse-admin",
        });

        factory.registerRoute({
          path: "/admin/parse/projectcontract",
          props: this.options,
          component: async () => ({ default: ProjectContractComponent }),
          permission: "parse-admin",
        });

        factory.registerRoute({
          path: "/researchprojects/overview",
          props: this.options,
          component: async () => ({ default: ProjectOverviewComponent }),
          permission: "rs:request",
        });

        factory.registerRoute({
          path: "/researchprojects/request",
          props: this.options,
          component: async () => ({ default: ProjectRequestComponent }),
          permission: "rs:request",
        });

        factory.registerRoute({
          path: "/researchprojects/invoice",
          props: this.options,
          component: async () => ({ default: ProjectInvoiceComponent }),
          permission: "rs:request",
        });

        factory.registerRoute({
          path: "/researchprojects/trips",
          props: this.options,
          component: async () => ({ default: ProjectTripComponent }),
          permission: "rs:request",
        });

        factory.registerStaticNavigationItem({
          id: "researchprojects/request",
          place: "frontpage",
          group: "researchprojects",
          order: 11,
          color: "#676767",
          label: "Forschungsprojekte",
          icon: "fa:search",
          link: "/researchprojects/overview",
          routeCondition: "**",
          activeCondition: "/researchprojects/overview",
          permission: "rs:request",
        });

        factory.registerStaticNavigationGroup({
          label: "Forschungsprojekte",
          order: 20,
          id: "researchprojects/projects",
        });

        factory.registerStaticNavigationGroup({
          label: "Forschungsprojekte",
          order: 20,
          id: "admin/researchprojects",
        });

        factory.registerStaticNavigationItem({
          id: "admin/projectadmin",
          group: "admin/researchprojects",
          place: "sidebar",
          order: 10,
          label: "Projekte",
          icon: "fa:folder",
          link: "/admin/parse/projects",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/projects",
          permission: "parse-admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/projectcontractadmin",
          group: "admin/researchprojects",
          place: "sidebar",
          order: 20,
          label: "Projektverträge",
          icon: "fa:file-contract",
          link: "/admin/parse/projectcontract",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/projectcontract",
          permission: "parse-admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/projectcalladmin",
          group: "admin/researchprojects",
          place: "sidebar",
          order: 30,
          label: "Abgerufene Mittels",
          icon: "fa:money-check-alt",
          link: "/admin/parse/moneycalls",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/moneycalls",
          permission: "parse-admin",
        });

        factory.registerStaticNavigationItem({
          id: "researchprojects/overviewside",
          group: "researchprojects/projects",
          place: "sidebar",
          order: 10,
          label: "Übersicht",
          icon: "fa:search",
          link: "/researchprojects/overview",
          routeCondition: "/researchprojects/**",
          activeCondition: "/researchprojects/overview",
          permission: "rs:request",
        });

        factory.registerStaticNavigationItem({
          id: "researchprojects/requestside",
          group: "researchprojects/projects",
          place: "sidebar",
          order: 20,
          label: "Mittelabruf",
          icon: "fa:calendar",
          link: "/researchprojects/request",
          routeCondition: "/researchprojects/**",
          activeCondition: "/researchprojects/request",
          permission: "rs:request",
        });

        factory.registerStaticNavigationItem({
          id: "researchprojects/invoiceside",
          group: "researchprojects/projects",
          place: "sidebar",
          order: 30,
          label: "Rechnungen",
          icon: "fa:file",
          link: "/researchprojects/invoice",
          routeCondition: "/researchprojects/**",
          activeCondition: "/researchprojects/invoice",
          permission: "rs:request",
        });

        factory.registerStaticNavigationItem({
          id: "researchprojects/tripside",
          group: "researchprojects/projects",
          place: "sidebar",
          order: 40,
          label: "Reisekosten",
          icon: "fa:car",
          link: "/researchprojects/trips",
          routeCondition: "/researchprojects/**",
          activeCondition: "/researchprojects/trips",
          permission: "rs:request",
        });

        $parse.ui.setClassConfig({
          className: "OD3_Projects",
          disableACLEditing: true,
          titleFields: ["Name"],
          displayFields: ["Name", "Start", "End", "Forderart", "Jahresstunden", "Forderquote", "Overhead", "TotalMoney"],
          createFields: ["Name", "Start", "End", "Forderart", "Jahresstunden", "Forderquote", "Overhead", "TotalMoney"],
          editFields: ["Name", "Start", "End", "Forderart", "Jahresstunden", "Forderquote", "Overhead", "TotalMoney"],
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _ => _.user, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant"), 'optionalAccess', _6 => _6.id]);
            if (tenantId) {
              acl["role:od-tenant-user-" + tenantId] = {
                read: true,
                write: false,
              };
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_Invoice",
          disableACLEditing: true,
          titleFields: ["InvoiceDate", "Name"],
          displayFields: ["Name", "Position", "InvoiceDate", "Description", "Supplier", "Netto", "Brutto", "Duration", "Project", "GWG", "Invoice"],
          createFields: ["Name", "Position", "InvoiceDate", "Description", "Supplier", "Netto", "Brutto", "Duration", "Project", "GWG", "Invoice"],
          editFields: ["Name", "Position", "InvoiceDate", "Description", "Supplier", "Netto", "Brutto", "Duration", "Project", "GWG", "Invoice"],
          customFields: {
            Position: (val) => {
              return SelectPosition(val);
            },
          },
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _7 => _7.user, 'access', _8 => _8.current, 'call', _9 => _9(), 'optionalAccess', _10 => _10.get, 'call', _11 => _11("tenant"), 'optionalAccess', _12 => _12.id]);
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_Trip",
          disableACLEditing: true,
          titleFields: ["Start", "End"],
          displayFields: ["Employee", "Project", "Start", "End", "Description", "Costs", "Documents"],
          createFields: ["Employee", "Project", "Start", "End", "Description", "Costs", "Documents"],
          editFields: ["Employee", "Project", "Start", "End", "Description", "Costs", "Documents"],
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _13 => _13.user, 'access', _14 => _14.current, 'call', _15 => _15(), 'optionalAccess', _16 => _16.get, 'call', _17 => _17("tenant"), 'optionalAccess', _18 => _18.id]);
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_ProjectCalls",
          disableACLEditing: true,
          titleFields: ["Anforderungsdatum", "Projekt"],
          displayFields: ["Anforderungsdatum", "Projekt", "Abrufssumme", "Abrufart"],
          createFields: ["Anforderungsdatum", "Projekt", "Abrufssumme", "Abrufart"],
          editFields: ["Anforderungsdatum", "Projekt", "Abrufssumme", "Abrufart"],
          customFields: {
            Abrufart: (val) => {
              return SelectAbrufart(val);
            },
          },
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _19 => _19.user, 'access', _20 => _20.current, 'call', _21 => _21(), 'optionalAccess', _22 => _22.get, 'call', _23 => _23("tenant"), 'optionalAccess', _24 => _24.id]);
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_ProjectContract",
          disableACLEditing: true,
          titleFields: ["Vertrag", "Projekt"],
          displayFields: ["Vertrag", "Projekt", "Monatsgehalt", "Projektanteil"],
          createFields: ["Vertrag", "Projekt", "Monatsgehalt", "Projektanteil"],
          editFields: ["Vertrag", "Projekt", "Monatsgehalt", "Projektanteil"],
          customFields: {
            Vertrag: (val) => {
              return SelectContract(val);
            },
          },
          defaultACL: () => {
            const acl = {};
            const tenantId = _optionalChain([$parse, 'access', _25 => _25.user, 'access', _26 => _26.current, 'call', _27 => _27(), 'optionalAccess', _28 => _28.get, 'call', _29 => _29("tenant"), 'optionalAccess', _30 => _30.id]);
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
          afterCreate: async (classname, objectId) => {
            const query = new Parse.Query(classname);
            query.equalTo("objectId", objectId);
            query.include("Vertrag");
            const obj = await query.get(objectId);
            const vertrag = obj.get("Vertrag");
            obj.setACL(vertrag.getACL());
            await obj.save();
        }
        });
        

        // Translations:
        factory.registerTranslationResolver(
          "en",
          "researchproject",
          async () => await import("./translations/en.json")
        );
    
        factory.registerTranslationResolver(
          "de",
          "researchproject",
          async () => await import("./translations/de.json")
        );
      }
    }