const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/Pieces/CalendarView.tsx";import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Calendar, Badge, message } from "antd";


















export const CalendarView = ({
  contract,
  isMobile,
  value,
  headerRender,
  onSelect,
  onPanelChange,
  selectedMonth,
  selectedYear,
  getMonthData,
  getListData,
  freeDays,
  setModalVisible,
}) => {
  const [vacData, setVacData] = React.useState([]);

  React.useEffect(() => {
    getVacDataOfUser();
  }, []);

  const getVacDataOfUser = () => {
    const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
    query.equalTo("User", Parse.User.current());
    query.equalTo("Status", 0);
    query.find().then((results) => {
      setVacData(results);
    });
  };

  const monthCellRender = (value) => {
    const currentMonth = dayjs().month();
    const num = getMonthData(value);

    if (value.month() === currentMonth) {
      return num ? (
        React.createElement('div', { className: "notes-month", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          , React.createElement('section', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, num)
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, "Backlog number" )
        )
      ) : null;
    } else {
      return null;
    }
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);

    return (
      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        , React.createElement('ul', {
          className: "events",
          style: { listStyleType: "none", paddingLeft: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}

          , listData.map((item) => (
            React.createElement('li', { key: item.content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              , React.createElement(Badge, {
                status: item.type ,
                text: item.content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
              )
            )
          ))
        )
      )
    );
  };

  const fullCellRender = (value) => {
    const currentMonth = selectedMonth;
    const currentYear = selectedYear;

    const isWeekend = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay = foundObject;

    const isInApplication = vacData.filter((obj) => {
      return (
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(obj.get("Start")).startOf("day")) &&
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(obj.get("End")).endOf("day"))
      );
    });

    if (value.month() === currentMonth && value.year() === currentYear) {
      return (
        React.createElement('div', {
          className: "ant-picker-cell-inner ant-picker-calendar-date" ,
          style: {
            backgroundColor:
              dateCellRenderMobile(value) == 1
                ? ""
                : dateCellRenderMobile(value) == 2
                ? "#fafafa"
                : "",
            margin: "1px",
          },
          onClick: (e) => {
            if (!isWeekend && !isBlockedDay && isInApplication.length === 0) {
              if (contract) {
                setModalVisible(true);
              } else {
                message.error(
                  "Es ist nicht möglich ohne einen Vertrag Stunden einzutragen!"
                );
              }
            } else if (isWeekend) {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an einem Wochenende einzutragen!"
              );
            } else if (isInApplication.length > 0) {
              message.error(
                "Sie haben für diesen Zeitraum Urlaub beantragt, daher kann nicht gearbeitet werden!"
              );
            } else {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an " +
                  foundObject.get("Reason") +
                  " einzutragen!"
              );
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}

          , React.createElement('div', {
            className: "ant-picker-calendar-date-value",
            style: {
              color:
                dateCellRenderMobile(value) == 1
                  ? ""
                  : dateCellRenderMobile(value) == 2
                  ? "red"
                  : "",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}

            , value.date()
          )
          , React.createElement('div', { className: "ant-picker-calendar-date-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
            , React.createElement('div', { className: "events", __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}, dateCellRender(value))
          )
        )
      );
    } else {
      return null;
    }
  };

  const dateCellRenderMobile = (value) => {
    const listData = getListData(value);
    const isWeekend = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj



) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay = foundObject;

    if (listData.length > 0) {
      return 1;
    } else if (isWeekend || isBlockedDay) {
      return 2;
    } else {
      return 3;
    }
  };

  const fullCellRenderMobile = (value) => {
    const currentMonth = selectedMonth;
    const currentYear = selectedYear;

    const isWeekend = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj



) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay = foundObject;

    const isInApplication = vacData.filter((obj) => {
      return (
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(obj.get("Start")).startOf("day")) &&
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(obj.get("End")).endOf("day"))
      );
    });

    if (value.month() === currentMonth && value.year() === currentYear) {
      return (
        React.createElement('div', {
          className: "ant-picker-cell-inner ant-picker-calendar-date" ,
          onClick: (e) => {
            if (!isWeekend && !isBlockedDay && isInApplication.length === 0) {
              setModalVisible(true);
            } else if (isWeekend) {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an einem Wochenende einzutragen!"
              );
            } else if (isInApplication.length > 0) {
              message.error(
                "Sie haben für diesen Zeitraum Urlaub beantragt, daher kann nicht gearbeitet werden!"
              );
            } else {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an " +
                  foundObject.get("Reason") +
                  " einzutragen!"
              );
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 234}}

          , React.createElement('div', {
            className: "ant-picker-calendar-date-value",
            style: {
              color:
                dateCellRenderMobile(value) == 1
                  ? "green"
                  : dateCellRenderMobile(value) == 2
                  ? "red"
                  : "",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}

            , value.date()
          )
        )
      );
    } else {
      return null;
    }
  };

  const cellRender = (
    current,
    info
  ) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  if (isMobile) {
    return (
      React.createElement(Calendar, {
        fullscreen: false,
        value: value,
        headerRender: headerRender,
        fullCellRender: fullCellRenderMobile,
        onSelect: onSelect,
        onPanelChange: onPanelChange,
        validRange: [
          dayjs().month(selectedMonth).year(selectedYear).startOf("month"),
          dayjs(selectedMonth)
            .month(selectedMonth)
            .year(selectedYear)
            .endOf("month"),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 287}}
      )
    );
  } else {
    return (
      React.createElement(Calendar, {
        headerRender: headerRender,
        cellRender: cellRender,
        value: value,
        onSelect: onSelect,
        onPanelChange: onPanelChange,
        fullCellRender: fullCellRender,
        validRange: [
          dayjs().month(selectedMonth).year(selectedYear).startOf("month"),
          dayjs(selectedMonth)
            .month(selectedMonth)
            .year(selectedYear)
            .endOf("month"),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}
      )
    );
  }
};
