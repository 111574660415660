const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/AdminPages/ContractComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";



export const ContractComponent = createComponent(function ContractComponent() {
  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      , React.createElement(ParseSetView, {
        className: "OD3_Contract",
        view: 
          {
            type: "table",
            customCols: [
              {
                title: "Vertragsbeginn",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, dayjs(_optionalChain([row, 'optionalAccess', _ => _.get, 'call', _2 => _2("Start")])).format("DD.MM.YYYY"))
                  );
                },
              } ,
              {
                title: "Vertragsende",
                key: "Datums2",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, dayjs(_optionalChain([row, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("End")])).format("DD.MM.YYYY"))
                  );
                },
              } ,
            ],
            fields: [
              "User",
              "HoursADay",
              "VacationDays",
              "MoneyInMonth",
              "Project",
              "ProjectPercent",
              "Status",
            ],
          } 
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      )
    )
  );
});
