const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/VacationComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";

import { Space, Col, Row, Statistic, Button, Spin, Select, Alert } from "antd";
import { VacationTable } from "./Pieces/VacationTable";
import { VacationModal } from "./Pieces/VacationModal";

export const VacationComponent = observer((props) => {
  const [modalVisible, setModalVisibility] = React.useState(false);
  const [parseDataForUser, setParseDataForUser] =
    React.useState(undefined);
  const [timeTrackingDataForUser, setTimeTrackingDataForUser] =
    React.useState([]);
  const [usedVacationDays, setUsedVacationDays] = React.useState(0);
  const [freeDays, setFreeDays] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [userData, setUserData] = React.useState([]);
  const [userMeta, setUserMeta] = React.useState(undefined);
  const [currentUser, setCurrentUser] = React.useState(
    Parse.User.current()
  );

  React.useEffect(() => {
    initUserData();
    initUserMeta();
    getFreeDays();
    getTimeTrackingDataForUser();
    getParseDataForUser();
  }, []);

  React.useEffect(() => {
    calcUsedVacation();
  }, [parseDataForUser]);

  React.useEffect(() => {
    initUserData();
    initUserMeta();
    getFreeDays();
    getTimeTrackingDataForUser();
    getParseDataForUser();
  }, [currentUser]);

  const initUserData = async () => {
    try {
      const query = new Parse.Query("_User").limit(99999999);
      const result = await query.find();
      setUserData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const initUserMeta = () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.equalTo("User", currentUser);
    query.find().then((results) => {
      const filteredObjects = results.find((obj) => {
        const startDate = obj.get("Start");
        const endDate = obj.get("End");
        const today = new Date();
        return (
          (today >= startDate && today <= endDate) ||
          today.toDateString() === startDate.toDateString() ||
          today.toDateString() === endDate.toDateString()
        );
      });
      setUserMeta(filteredObjects);
      setLoading(false);
    });
  };

  const addVacationApplication = (
    startDate,
    endDate,
    type,
    startEndDate1,
    startEndDate2
  ) => {
    if (!checkIfDaysAreEmpty(startDate, endDate)) {
      return false;
    } else {
      const vacationObject = new Parse.Object("OD3_TimeVacation");
      vacationObject.set("Start", startDate);
      vacationObject.set("End", endDate);
      vacationObject.set("Type", type);
      vacationObject.set("Status", 0);
      vacationObject.set("User", currentUser);
      vacationObject.set("Days", anzahlWerktagen(startEndDate1, startEndDate2));

      const acl = new Parse.ACL();
      const currentUserACL = currentUser;
      if (currentUserACL) {
        acl.setReadAccess(currentUserACL, true);
        acl.setWriteAccess(currentUserACL, true);
      }
      acl.setRoleReadAccess(
        "od-tenant-admin-" + _optionalChain([$parse, 'access', _ => _.user, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant"), 'optionalAccess', _6 => _6.id]),
        true
      );
      acl.setRoleWriteAccess(
        "od-tenant-admin-" + _optionalChain([$parse, 'access', _7 => _7.user, 'access', _8 => _8.current, 'call', _9 => _9(), 'optionalAccess', _10 => _10.get, 'call', _11 => _11("tenant"), 'optionalAccess', _12 => _12.id]),
        true
      );
      vacationObject.setACL(acl);

      vacationObject
        .save()
        .then(() => {
          getParseDataForUser();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return true;
  };

  const checkIfDaysAreEmpty = (start, end) => {
    let foundObject = timeTrackingDataForUser.filter(
      (obj) =>
        dayjs(obj.get("Date"))
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(start).startOf("day")) &&
        dayjs(obj.get("Date"))
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(end).endOf("day"))
    );
    return foundObject.length === 0;
  };

  const getFreeDays = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(99999999);
      const result = await query.find();
      setFreeDays(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der freien Tage:", error);
    }
  };

  const anzahlWerktagen = (startDatum, endDatum) => {
    let werktage = 0;
    let currentDate = dayjs(startDatum);

    while (
      currentDate.isBefore(endDatum) ||
      currentDate.isSame(endDatum, "day")
    ) {
      if (currentDate.day() > 0 && currentDate.day() < 6) {
        const foundObject = freeDays.find(
          (obj) =>
            dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
            currentDate.format("DD.MM.YYYY")
        );
        if (!foundObject) {
          werktage++;
        }
      }
      currentDate = currentDate.add(1, "day"); // Zum nächsten Tag gehen
    }

    return werktage;
  };

  const deleteForm = async (form) => {
    try {
      const VacationClass = Parse.Object.extend("OD3_TimeVacation");
      let toDelete = await VacationClass.createWithoutData(form.key).fetch();
      await toDelete.destroy().then(() => {
        getParseDataForUser();
      });
    } catch (error) {
      console.error("Fehler beim Löschen des Eintrags:", error);
    }
  };

  const getTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("createdAt");

      const result = await query.find();
      let foundObject = result.filter(
        (obj) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _13 => _13.id])
      );
      if (foundObject && foundObject.length > 0) {
        setTimeTrackingDataForUser(foundObject);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getParseDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
      query.descending("createdAt");
      const result = await query.find();
      let foundObject = result.filter(
        (obj) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _14 => _14.id])
      );
      if (foundObject && foundObject.length > 0) {
        setParseDataForUser(foundObject);
      } else {
        setParseDataForUser([]);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const calcUsedVacation = () => {
    if (parseDataForUser && parseDataForUser.length > 0) {
      let usedVacationDays = 0;
      parseDataForUser.forEach((element) => {
        if (element.get("Status") != 1) return;
        usedVacationDays += element.get("Days");
      });
      setUsedVacationDays(usedVacationDays);
    } else {
      setUsedVacationDays(0);
    }
  };

  return (
    React.createElement('div', { style: { margin: "24px", width: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
      , React.createElement(AdminToolbar, {
        title: "Urlaubsverwaltung",
        description: "Antrag auf Urlaub und Übersicht über den Urlaubsstand."       ,
        search: "",
        actions: [
          React.createElement(Button, {
            key: "1",
            onClick: () => {
              setModalVisibility(true);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
, "Neuer Antrag"

          ),
          _optionalChain([$parse, 'access', _15 => _15.user, 'access', _16 => _16.current, 'call', _17 => _17(), 'optionalAccess', _18 => _18.get, 'call', _19 => _19("tenantAdmin")]) ? (
            React.createElement(Select, {
              key: "2",
              defaultValue: _optionalChain([currentUser, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("name")]),
              style: { width: 120 },
              onChange: (e) => {
                setCurrentUser(userData[e]);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}

              , userData.map((user, index) => (
                React.createElement(Select.Option, { key: user.id, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
                  , user.get("name")
                )
              ))
            )
          ) : null,
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}
      )

      , loading && (
        React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 266}}

          , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 276}} )
        )
      )
      , !loading && (
        React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}

          , React.createElement(Space, { direction: "vertical", size: "middle", style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 286}}
            , userMeta && (
              React.createElement(Row, { gutter: 24, style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 288}}
                , React.createElement(Col, { xs: 24, sm: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}
                  , React.createElement(Statistic, {
                    style: { textAlign: "center" },
                    valueStyle: {
                      textAlign: "center",
                      color:
                        usedVacationDays > _optionalChain([userMeta, 'optionalAccess', _22 => _22.get, 'call', _23 => _23("VacationDays")])
                          ? "#f50"
                          : "#87d068",
                    },
                    title: "Genommene Urlaubstage" ,
                    value: usedVacationDays,
                    precision: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
                  )
                )
                , React.createElement(Col, { xs: 24, sm: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 304}}
                  , React.createElement(Statistic, {
                    style: { textAlign: "center" },
                    valueStyle: { textAlign: "center" },
                    title: "Urlaubstage " + dayjs().year(),
                    value: _optionalChain([userMeta, 'optionalAccess', _24 => _24.get, 'call', _25 => _25("VacationDays")]),
                    precision: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}
                  )
                )
                , React.createElement(Col, { xs: 24, sm: 8, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
                  , React.createElement(Statistic, {
                    style: {
                      textAlign: "center",
                    },
                    valueStyle: {
                      textAlign: "center",
                      color:
                        usedVacationDays > _optionalChain([userMeta, 'optionalAccess', _26 => _26.get, 'call', _27 => _27("VacationDays")])
                          ? "#f50"
                          : "#87d068",
                    },
                    title: "Restliche Urlaubstage" ,
                    value: _optionalChain([userMeta, 'optionalAccess', _28 => _28.get, 'call', _29 => _29("VacationDays")]) - usedVacationDays,
                    precision: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 314}}
                  )
                )
              )
            )
            , !userMeta && (
              React.createElement(Alert, {
                style: { textAlign: "center" },
                message: 
                  "Es ist kein Vertrag mehr Vorhanden. Es können aber noch Urlaube nachgereicht werden!"
                ,
                type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}
              )
            )
            , React.createElement('div', { style: { paddingTop: "40px", paddingBottom: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
              , React.createElement(VacationTable, {
                data: parseDataForUser ? parseDataForUser : [],
                deleteForm: deleteForm, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
              )
            )
          )
        )
      )
      , React.createElement(VacationModal, {
        modal: modalVisible,
        setModalVisibility: setModalVisibility,
        addVacationApplication: addVacationApplication,
        anzahlWerktagen: anzahlWerktagen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 350}}
      )
    )
  );
});
