const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/SelectPosition.tsx";import React from "react";
import { Select } from "antd";


export const SelectPosition = (
  props



) => {
  const { value, setValue, fieldname, parseObject } = props;

  return (
    React.createElement(Select, {
      value: value,
      onChange: (e) => {
        setValue(e);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}

      , React.createElement(Select.Option, { key: 1, value: "0813", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "0813 Material"

      )
      , React.createElement(Select.Option, { key: 2, value: "0823", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "0823 FE-Fremdleistung"

      )
      , React.createElement(Select.Option, { key: 3, value: "0847", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "0847 Abschreibungen"

      )
    )
  );
};
