const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/AdminPages/ProjectComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";



export const ProjectComponent = createComponent(function ProjectComponent() {
  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      , React.createElement(ParseSetView, {
        className: "OD3_Projects",
        view: 
          {
            type: "table",
            customCols: [
              {
                title: "Projektstart",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, dayjs(_optionalChain([row, 'optionalAccess', _ => _.get, 'call', _2 => _2("Start")])).format("DD.MM.YYYY"))
                  );
                },
              } ,
              {
                title: "Projektende",
                key: "Datums2",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, dayjs(_optionalChain([row, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("End")])).format("DD.MM.YYYY"))
                  );
                },
              } ,
              {
                title: "Fördersumme",
                key: "TotalMoney",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
                      , _optionalChain([row, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("TotalMoney"), 'access', _7 => _7.toLocaleString, 'call', _8 => _8("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })]), " ", "€"

                    )
                  );
                },
              } ,
            ],
            fields: [
              "Name",
              "Forderart",
              "Jahresstunden",
              "Forderquote",
              "Overhead",
            ],
          } 
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      )
    )
  );
});
