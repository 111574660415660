const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/ProjectPersonal.tsx";import React from "react";
import dayjs from "dayjs";

import { Table } from "antd";







export const ProjectPersonal = ({
  dataSource,
  currentQuarter,
}) => {
  let columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name" + Math.random(),
      render: (text) => React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, text),
    },
    {
      title: "Jahresstunden",
      align: "center",
      dataIndex: "yearhours",
      key: "yearhours",
    },
    {
      title: "Jahreskosten",
      align: "center",
      dataIndex: "yearcosts",
      key: "yearcosts",
    },
    {
      title: "Monat1",
      align: "center",
      dataIndex: "month1",
      key: "month1",
    },
    {
      title: "Monat2",
      align: "center",
      dataIndex: "month2",
      key: "month2",
    },
    {
      title: "Monat3",
      align: "center",
      dataIndex: "month3",
      key: "month3",
    },
    {
      title: "Gesamtstunden",
      align: "center",
      dataIndex: "sum",
      key: "sum",
    },
    {
      title: "Kostensatz",
      align: "center",
      dataIndex: "costRate",
      key: "costRate",
    },
    {
      title: "Quartalskosten",
      align: "center",
      dataIndex: "costsInQuarter",
      key: "costsInQuarter",
    },
    {
      title: "Quartalskosten mit Overhead",
      align: "center",
      dataIndex: "costsInQuarterWithOverhead",
      key: "costsInQuarterWithOverhead",
    },
  ];

  const [tableColumns, setTableColumns] =
    React.useState(columns);

  React.useEffect(() => {
    if (currentQuarter) {
      columns[3].title =
        currentQuarter.length > 2
          ? dayjs(currentQuarter[0] , "MM.YYYY").format("MMMM YYYY")
          : "Monat 1";
      columns[4].title =
        currentQuarter.length > 1
          ? dayjs(currentQuarter[1] , "MM.YYYY").format("MMMM YYYY")
          : "Monat 2";
      columns[5].title =
        currentQuarter.length > 2
          ? dayjs(currentQuarter[2] , "MM.YYYY").format("MMMM YYYY")
          : "Monat 3";
    }
    setTableColumns(columns);
  }, [currentQuarter]);

  return (
    React.createElement(Table, {
      size: "small",
      columns: tableColumns,
      dataSource: dataSource,
      pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
    )
  );
};
