const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/ProjectOverviewComponent.tsx";import React from "react";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { createComponent } from "@opendash/state";
import { AdminToolbar } from "@opendash/ui";
import { MultiTimeline } from "./Pieces/MultiTimeLine";
import { Flex, Progress, Typography, Card, Statistic } from "antd";
const { Title } = Typography;








export const ProjectOverviewComponent = createComponent(
  function ProjectOverviewComponent() {
    const [projectData, setProjectData] = React.useState([]);
    const [eventData, setEventData] = React.useState([]);
    const [lastDate, setLastDate] = React.useState(new Date());

    React.useEffect(() => {
      initProjectData();
    }, []);

    




    const initProjectData = async () => {
      try {
        let events = [];
        const query = new Parse.Query("OD3_Projects").limit(99999999);
        query.ascending("End");
        const result = await query.find();

        const projectDataPuffer = [];
        const promises = result.map(async (project) => {
          if (project.get("Name") != "open.INC Intern") {
            const queryMoney = new Parse.Query("OD3_ProjectCalls").limit(
              99999999
            );
            queryMoney.equalTo("Projekt", project);
            let resultMoney = await queryMoney.find();
            let usedMoney = 0;
            resultMoney.forEach((money) => {
              usedMoney += money.get("Abrufssumme");
            });
            projectDataPuffer.push({
              name: project.get("Name"),
              totalMoney: project.get("TotalMoney"),
              usedMoney: usedMoney,
              percentage: Math.round(
                (usedMoney / project.get("TotalMoney")) * 100
              ),
            });
            events.push({
              endDate: project.get("End"),
              name: project.get("Name"),
            });
          }
        });

        await Promise.all(promises);
        setProjectData(projectDataPuffer);
        setEventData(events);
        const today = new Date();
        const futureDate = new Date(
          today.getFullYear(),
          today.getMonth() + 36,
          1
        );
        futureDate.setMonth(futureDate.getMonth() + 1);
        futureDate.setDate(0);
        setLastDate(futureDate);
      } catch (error) {
        console.error(error);
      }
    };

    const today = new Date();

    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        , React.createElement(AdminToolbar, {
          title: "Forschungsprojekte",
          description: "Übersicht über alle Projekte und deren Laufzeiten."      ,
          search: "",
          actions: [], __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
        )
        , React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}

          , React.createElement(MultiTimeline, {
            start: today,
            end: lastDate,
            today: today,
            events: eventData, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}} )
          , React.createElement('div', { style: { width: "100%", textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
            , React.createElement(Title, { level: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}, "Kostenübersicht")
          )
          , React.createElement(Flex, { wrap: true, gap: "small", justify: "space-evenly", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            , projectData.map((project) => {
              return (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
                  , React.createElement('div', {
                    style: {
                      width: "100%",
                      textAlign: "center",
                      padding: "15px",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}

                    , React.createElement(Card, {
                      style: { width: "200px" },
                      hoverable: true,
                      bordered: false,
                      cover: 
                        React.createElement(Progress, {
                          type: "dashboard",
                          trailColor: "rgba(0, 0, 0, 0.06)"   ,
                          strokeWidth: 20,
                          steps: 10,
                          percent: project.percentage,
                          format: (percent) => (
                            React.createElement('div', { style: { fontSize: "16px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
                              , percent + "% ", " " , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}} )
                              , React.createElement('div', { style: { fontSize: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
                                , project.name
                              )
                            )
                          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
                        )
                      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}

                      , React.createElement(Statistic, {
                        title: "Gesamtförderung",
                        value: project.totalMoney,
                        precision: 2,
                        valueStyle: { color: "#3f8600" },
                        suffix: "€", __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
                      )
                      , React.createElement(Statistic, {
                        title: "Abgerufen",
                        value: project.usedMoney,
                        precision: 2,
                        valueStyle: { color: "#aaa" },
                        suffix: "€", __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
                      )
                      , React.createElement(Statistic, {
                        title: "Noch verfügbar" ,
                        value: project.totalMoney - project.usedMoney || 0,
                        precision: 2,
                        valueStyle: { color: "#aaa" },
                        suffix: "€", __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
                      )
                    )
                  )
                )
              );
            })
          )
        )
      )
    );
  }
);
