const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/ProjectTripComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { Icon } from "@opendash/icons";



export const ProjectTripComponent = createComponent(
  function ProjectTripComponent() {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        , React.createElement(ParseSetView, {
          className: "OD3_Trip",
          view: 
            {
              type: "table",
              customCols: [
                {
                  title: "Startdatum",
                  key: "Start",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, dayjs(_optionalChain([row, 'optionalAccess', _ => _.get, 'call', _2 => _2("Start")])).format("DD.MM.YYYY"))
                    );
                  },
                } ,
                {
                  title: "Enddatum",
                  key: "End",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, dayjs(_optionalChain([row, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("End")])).format("DD.MM.YYYY"))
                    );
                  },
                } ,

                {
                  title: "Beschreibung",
                  key: "Description",
                  dataIndex: 0,
                  render: (v, row) => {
                    return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, _optionalChain([row, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("Description")]));
                  },
                } ,

                {
                  title: "Kosten",
                  key: "Costs",
                  dataIndex: 0,
                  render: (v, row) => {
                    return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}, _optionalChain([row, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("Costs")]), " €" );
                  },
                } ,

                {
                  title: "Belege",
                  key: "Documents",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                        , React.createElement(Icon, {
                          style: { fontSize: "20px" },
                          icon: "fa:file-pdf",
                          onClick: () => {
                            window.open(row.get("Documents")._url, "_blank");
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
)
                      )
                    );
                  },
                } ,
              ],
              fields: ["Employee", "Project"],
            } 
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        )
      )
    );
  }
);
