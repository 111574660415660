const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/Timeline.tsx";import React from "react";
import dayjs from "dayjs";







export const Timeline = ({ start, end, today }) => {
  const [todayWidth, setTodayWidth] = React.useState("0%");

  React.useEffect(() => {
    initTimeLine();
  }, [start, end, today]);

  const initTimeLine = () => {
    const startDate = start;
    const endDate = end;

    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.ceil(
      ((today ) - startDate) / (1000 * 60 * 60 * 24)
    );

    const todayWidthPercentage = (daysPassed / totalDays) * 100;
    setTodayWidth(`${todayWidthPercentage}%`);
  };

  return (
    React.createElement('div', {
      style: {
        position: "relative",
        width: "100%",
        height: "70px",
        overflow: "hidden",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}

      /* Startdatum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "73%",
          left: "0",
          transform: "translateY(-50%)",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, dayjs(start).format("DD.MM.YYYY"))
      )

      /* Enddatum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "100%",
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "73%",
          right: "0",
          transform: "translateY(-50%)",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, dayjs(end).format("DD.MM.YYYY"))
      )

      /* Zeitstrahl */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translateY(-50%)",
          width: "100%",
          height: "2px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "25%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "15px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "20px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
)
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: "75%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "15px",
          backgroundColor: "#aaa",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
)

      /* Aktueller Tag */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "50%",
          left: todayWidth,
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "30px",
          backgroundColor: "#2db7f5",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
)

      /* Heutiges Datum */
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: "-11%",
          left: todayWidth,
          transform: "translate(-50%, 50%)",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}

        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}, "Heute")
      )
    )
  );
};
