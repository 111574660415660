const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/Pieces/EntryModal.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { Icon } from "@opendash/icons";
import {
  Row,
  Select,
  Col,
  Modal,
  Input,
  Tooltip,
  Descriptions,
  Checkbox,
  Popconfirm,
  Statistic,
} from "antd";


























export const EntryModal = ({
  props,
  currentUser,
  modalTitle,
  modalVisible,
  vacationChecked,
  handleVacationChange,
  hoursWorked,
  handlePercentageChange,
  percentage,
  handleCommentChange,
  comment,
  sickLeaveChecked,
  handleSickLeaveChange,
  informationText,
  handleModalOk,
  handleModalCancel,
  getOwnList,
  checkDeleteStatus,
  deleteCurrentEntry,
  setModalVisible,
  getAllList,
  setHoursWorked,
}) => {
  return (
    React.createElement(Modal, {
      title: modalTitle,
      open: modalVisible,
      onOk: !getOwnList ? handleModalOk : () => {},
      okText: "Speichern",
      okButtonProps: {
        style: { display: !getOwnList ? "" : "none" },
      },
      onCancel: handleModalCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}

      , getOwnList && (
        React.createElement('div', {
          style: {
            marginBottom: 20,
            padding: 16,
            backgroundColor: "#f9f9f9",
            borderRadius: 4,
            border: "1px solid #e8e8e8",
            fontSize: 12,
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}

          , React.createElement('div', { style: { marginBottom: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
            , !getOwnList.get("Sick") && !getOwnList.get("Vacation") && (
              React.createElement(Statistic, {
                title: getOwnList.get("User").get("name"),
                value: getOwnList.get("WorkingHours") + "  Stunden",
                precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
              )
            )
            , getOwnList.get("Sick") && (
              React.createElement(Statistic, {
                title: getOwnList.get("User").get("name"),
                value: "Krankheit",
                precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
              )
            )
            , getOwnList.get("Vacation") && (
              React.createElement(Statistic, {
                title: getOwnList.get("User").get("name"),
                value: "Urlaub",
                precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
              )
            )
            , getOwnList.get("Comment") && (
              React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}, "Kommentar: " , getOwnList.get("Comment"))
            )
          )
          , checkDeleteStatus(getOwnList) && (
            React.createElement(Popconfirm, {
              title: "Wirklich löschen?" ,
              onConfirm: (e) => {
                deleteCurrentEntry(getOwnList);
                setModalVisible(false);
              },
              onCancel: (e) => {},
              okText: "Löschen",
              cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}

              , React.createElement('div', {
                style: { marginLeft: 8, color: "red", fontSize: "24px" },
                onClick: () => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}

                , React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} )
              )
            )
          )
        )
      )
      , getAllList.length > 0 &&
        getAllList.map((element, index) => (
          React.createElement('div', {
            key: index,
            style: {
              marginBottom: 20,
              padding: 16,
              backgroundColor: "#fefefe",
              borderRadius: 4,
              border: "1px dashed #a1a1a1",
              fontSize: 12,
              color: "#333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}

            , React.createElement('p', { style: { marginBottom: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
              , !element.get("Sick") && !element.get("Vacation") && (
                React.createElement(Statistic, {
                  title: _optionalChain([element, 'access', _ => _.get, 'call', _2 => _2("User"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("name")]),
                  value: element.get("WorkingHours") + "  Stunden",
                  precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
                )
              )
              , element.get("Sick") && (
                React.createElement(Statistic, {
                  title: _optionalChain([element, 'access', _5 => _5.get, 'call', _6 => _6("User"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("name")]),
                  value: "Krankheit",
                  precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
                )
              )
              , element.get("Vacation") && (
                React.createElement(Statistic, {
                  title: _optionalChain([element, 'access', _9 => _9.get, 'call', _10 => _10("User"), 'optionalAccess', _11 => _11.get, 'call', _12 => _12("name")]),
                  value: "Urlaub",
                  precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
                )
              )
              , element.get("Comment") && (
                React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}, "Kommentar: " , element.get("Comment"))
              )
            )
            , checkDeleteStatus(element) && (
              React.createElement(Popconfirm, {
                title: "Wirklich löschen?" ,
                onConfirm: (e) => {
                  deleteCurrentEntry(element);
                  setModalVisible(false);
                },
                onCancel: (e) => {},
                okText: "Löschen",
                cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}

                , React.createElement('div', {
                  style: {
                    marginLeft: 8,
                    color: "red",
                    fontSize: "24px",
                  },
                  onClick: () => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}

                  , React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}} )
                )
              )
            )
          )
        ))
      , !getOwnList && (
        React.createElement(Row, { gutter: [16, 16], __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
          , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}
            , React.createElement(Descriptions, { column: 1, bordered: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 209}}
              , React.createElement(Descriptions.Item, { label: "Arbeitszeit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}
                , React.createElement('div', { style: { display: "flex", alignItems: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}
                  , React.createElement(Input, {
                    value: hoursWorked,
                    onChange: (e) => {
                      try {
                        let value = e.target.value;

                        setHoursWorked(value);
                      } catch (e) {
                        console.error(e);
                      }
                    },
                    style: { width: "25%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}
                  )
                  , React.createElement('span', { style: { marginLeft: 8, marginTop: "16px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}
                    , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 226}}, "Stunden")
                  )
                )
              )
              , React.createElement(Descriptions.Item, { label: "Kommentar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}
                , React.createElement(Input.TextArea, {
                  value: comment,
                  onChange: handleCommentChange,
                  placeholder: "Kommentar",
                  autoSize: { minRows: 3, maxRows: 5 },
                  style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
                )
              )
              , props.researchprojectPlugin && (
                React.createElement(Descriptions.Item, { label: "Projektanteil", __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
                  , React.createElement('div', { style: { display: "flex", alignItems: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 241}}
                    , React.createElement(Select, {
                      style: { flex: 1 },
                      value: percentage,
                      onChange: handlePercentageChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}

                      , [...Array(11).keys()].map((value) => (
                        React.createElement(Select.Option, { key: value * 10, value: value * 10, __self: this, __source: {fileName: _jsxFileName, lineNumber: 248}}
                          , value * 10, "%"
                        )
                      ))
                    )
                    , React.createElement(Tooltip, { title: "Der Anteil der Arbeitszeit der auf einem Projekt gearbeitet wurde. Bitte geben Sie 0% an, wenn die ganztägig auf Dienstreise für einen Kunden waren oder ganztätig für einen Kunden tätig waren."                              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 253}}
                      , React.createElement('span', { style: { marginLeft: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}
                        , React.createElement(Icon, { icon: "fa:info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 255}} )
                      )
                    )
                  )
                )
              )
              , React.createElement(Descriptions.Item, { label: "Sonstiges", __self: this, __source: {fileName: _jsxFileName, lineNumber: 261}}
                , currentUser.get("VacationWithoutApplication") && (
                  React.createElement(Checkbox, {
                    style: { width: "100%" },
                    checked: vacationChecked,
                    onChange: handleVacationChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 263}}
, "Urlaub"

                  )
                )
                , React.createElement(Checkbox, {
                  style: { width: "100%" },
                  checked: sickLeaveChecked,
                  onChange: handleSickLeaveChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}
, "Krankheit"

                )
              )
            )
          )
        )
      ), " "
      , !getOwnList && (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 284}}
          , React.createElement('p', {
            style: {
              marginBottom: 8,
              marginTop: 20,
              padding: 16,
              textAlign: "justify",
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              borderRadius: 4,
              border: "1px solid #e8e8e8",
              fontSize: 11,
              color: "#333",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}}

            , informationText
          )
        )
      )
    )
  );
};
