const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/AdminPages/MoneyCallComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";



export const MoneyCallComponent = createComponent(
  function MoneyCallComponent() {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , React.createElement(ParseSetView, {
          className: "OD3_ProjectCalls",
          view: 
            {
              type: "table",
              customCols: [
                {
                  title: "Anforderungsdatum",
                  key: "Anforderungsdatum",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
                        , dayjs(_optionalChain([row, 'optionalAccess', _ => _.get, 'call', _2 => _2("Anforderungsdatum")])).format(
                          "DD.MM.YYYY"
                        )
                      )
                    );
                  },
                } ,
                {
                  title: "Abrufsumme",
                  key: "Abrufsumme",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
                        , _optionalChain([row, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("Abrufssumme"), 'access', _5 => _5.toLocaleString, 'call', _6 => _6("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })]), " ", "€"

                      )
                    );
                  },
                } ,
              ],
              fields: ["Projekt", "Abrufart"],
            } 
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        )
      )
    );
  }
);
