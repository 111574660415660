const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/ProjectRequestComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import Parse from "parse";

import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { Space, Spin, Select, Typography, Collapse } from "antd";
import { AdminLayout } from "@opendash/core";
import { Timeline } from "./Pieces/Timeline";
import { ProjectPersonal } from "./Pieces/ProjectPersonal";
import { ProjectInvest } from "./Pieces/ProjectInvest";
import { ProjectTrip } from "./Pieces/ProjectTrip";

import { ProjectRequest } from "./Pieces/ProjectRequest";
const { Title } = Typography;
const { Panel } = Collapse;

export const ProjectRequestComponent = observer((props) => {
  const [loading, setLoading] = React.useState(true);
  const [overAllCosts, setOverAllCosts] = React.useState([]);
  const [currentProject, setCurrentProject] = React.useState();
  const [projectData, setProjectData] = React.useState([]);
  const [userMeta, setUserMeta] = React.useState([]);
  const [projectMeta, setProjectMeta] = React.useState([]);
  const [projectWorker, setProjectWorker] = React.useState([]);
  const [monthsInQuarter, setMonthsInQuarter] = React.useState([]);
  const [generatedQuarters, setGeneratedQuarters] = React.useState([]);
  const [personalKosten, setPersonalkosten] = React.useState([]);
  const [otherKosten, setOtherkosten] = React.useState([]);
  const [personalKostenNumber, setPersonalkostenNumber] = React.useState

([]);
  const [otherKostenNumber, setOtherkostenNumber] = React.useState(
    []
  );
  const [materialKosten, setMaterialkosten] = React.useState([]);
  const [investKosten, setInvestkosten] = React.useState([]);
  const [rdKosten, setRDkosten] = React.useState([]);
  const [tripKosten, setTripkosten] = React.useState([]);
  const [selectedQuarter, setSelectedQuarter] = React.useState(-1);
  const [personalKostenTable, setPersonalkostenTable] = React.useState

([]);
  const [materialKostenTable, setMaterialkostenTable] = React.useState

([]);
  const [investKostenTable, setInvestkostenTable] = React.useState

([]);
  const [rdKostenTable, setRDkostenTable] = React.useState(
    []
  );
  const [otherKostenTable, setOtherkostenTable] = React.useState

([]);
  const [tripKostenTable, setTripkostenTable] = React.useState(
    []
  );

  React.useEffect(() => {
    initProjectData();
  }, []);

  React.useEffect(() => {
    initProjectData();
  }, [currentProject]);

  React.useEffect(() => {
    initUserMeta(generatedQuarters, selectedQuarter);
    calcAllCosts();
  }, [selectedQuarter]);

  React.useEffect(() => {
    initProjectWorker();
  }, [userMeta, projectMeta]);

  React.useEffect(() => {
    calculateQuarterPerPerson();
  }, [projectWorker]);

  React.useEffect(() => {
    calcOverAllCosts();
  }, [
    personalKosten,
    otherKosten,
    rdKosten,
    materialKosten,
    tripKosten,
    investKosten,
  ]);

  React.useEffect(() => {
    calcAllCosts();
  }, [personalKosten, otherKosten]);

  React.useEffect(() => {
    if (
      generatedQuarters.length > 0 &&
      selectedQuarter > -1 &&
      _optionalChain([generatedQuarters, 'access', _ => _[selectedQuarter], 'optionalAccess', _2 => _2.getMonth, 'call', _3 => _3()]) != undefined &&
      monthsInQuarter.length > 0
    ) {
      setLoading(false);
    }
  }, [generatedQuarters]);

  const calcAllCosts = () => {
    personalKostenTableData();
    otherKostenTableData();
    investKostenTableData(generatedQuarters[selectedQuarter + 1]);
    materialKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
    rdKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
    travelKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
  };

  const calcOverAllCosts = () => {
    let oldOverAllCosts = 0;
    let newOverAllCosts = 0;

    oldOverAllCosts =
      materialKosten[1] +
      investKosten[1] +
      rdKosten[1] +
      tripKosten[1] +
      personalKostenNumber[1] +
      otherKostenNumber[1];
    newOverAllCosts =
      materialKosten[0] +
      investKosten[0] +
      rdKosten[0] +
      tripKosten[0] +
      personalKostenNumber[0] +
      otherKostenNumber[0];

    setOverAllCosts([newOverAllCosts, oldOverAllCosts]);
  };

  const initProjectData = async () => {
    try {
      let currentProjectVar =
        currentProject || new Parse.Object();
      const query = new Parse.Query("OD3_Projects").limit(99999999);
      const result = await query.find();
      setProjectData(result);
      if (!currentProject && result.length > 0) {
        currentProjectVar = result[0];
      }

      const quarters = [];
      const monthsInQuarterListList = [];
      let acutalQuarter = -1;
      let currentQuarter = new Date(
        currentProjectVar.get("Start").getFullYear(),
        Math.floor(currentProjectVar.get("Start").getMonth() / 3) * 3,
        1
      );
      while (currentQuarter < currentProjectVar.get("End")) {
        let currentQuarterBuffer = new Date(
          JSON.parse(JSON.stringify(currentQuarter))
        );
        let monthsInQuarterList = [];
        monthsInQuarterList.push(dayjs(currentQuarterBuffer).format("MM.YYYY"));
        monthsInQuarterList.push(
          dayjs(
            currentQuarterBuffer.setMonth(currentQuarterBuffer.getMonth() + 1)
          ).format("MM.YYYY")
        );
        monthsInQuarterList.push(
          dayjs(
            currentQuarterBuffer.setMonth(currentQuarterBuffer.getMonth() + 1)
          ).format("MM.YYYY")
        );
        quarters.push(new Date(currentQuarter));
        currentQuarter.setMonth(currentQuarter.getMonth() + 3);
        monthsInQuarterListList.push(monthsInQuarterList);
      }

      const today = new Date();

      for (let i = 0; i < quarters.length; i++) {
        const quarterStart = quarters[i];
        const quarterEnd = new Date(quarterStart);
        quarterEnd.setMonth(quarterEnd.getMonth() + 3);

        if (today >= quarterStart && today < quarterEnd) {
          acutalQuarter = i;
          break;
        }
      }

      if (currentProject != currentProjectVar) {
        setCurrentProject(currentProjectVar);
      }
      setGeneratedQuarters(quarters);
      setMonthsInQuarter(monthsInQuarterListList);

      if (acutalQuarter != -1) {
        setSelectedQuarter(acutalQuarter);
        initUserMeta(quarters, acutalQuarter);
      } else {
        setSelectedQuarter(quarters.length - 1);
        initUserMeta(quarters, quarters.length - 1);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const initUserMeta = async (
    quarterList,
    selectedQuarterVar
  ) => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.include("User");
    let results = await query.find();

    //ProjectMeta
    const queryProjectMeta = new Parse.Query("OD3_ProjectContract").limit(
      99999999
    );
    queryProjectMeta.include("Vertrag");
    queryProjectMeta.include("Projekt");
    let resultsProject = await queryProjectMeta.find();
    setProjectMeta(resultsProject);

    const filteredObjects = results.filter((obj) => {
      const startDate = dayjs(obj.get("Start")).format("MM.YYYY");
      const endDate = dayjs(obj.get("End")).format("MM.YYYY");
      const today = dayjs(quarterList[selectedQuarterVar]).format("MM.YYYY");
      return (
        today == startDate ||
        today == endDate ||
        (startDate < today && endDate > today)
      );
    });

    let userSeen = [];
    let userDouble = [];
    let sindgleContracts = [];
    let doubleContracts = [];
    filteredObjects.forEach((obj, index) => {
      if (!userSeen.includes(obj.get("User").id)) {
        userSeen.push(obj.get("User").id);
        sindgleContracts.push(obj);
      } else {
        userDouble.push(obj.get("User").id);
        doubleContracts.push(
          sindgleContracts[userSeen.indexOf(obj.get("User").id)]
        );
        doubleContracts.push(obj);
      }
    });

    let betterIndex = doubleContracts.findIndex((contract) => {
      const closestObject = findClosestObject(
        doubleContracts,
        quarterList[selectedQuarterVar]
      );
      return contract === closestObject;
    });
    try {
      const filteredObjectsSingle = filteredObjects.filter(
        (obj) => obj.id !== doubleContracts[betterIndex].id
      );

      setUserMeta(filteredObjectsSingle);
    } catch (error) {
      setUserMeta(filteredObjects);
    }
  };

  const findClosestObject = (objects, date) => {
    if (date) {
      const calculateDifference = (time1, time2) => {
        return Math.abs(time1.getTime() - time2.getTime());
      };

      let closestObject = null;
      let minDifference = Infinity;

      objects.forEach((obj) => {
        const startTime = obj.get("Start");
        const endTime = obj.get("End");

        const startDifference = calculateDifference(startTime, date);

        const endDifference = calculateDifference(endTime, date);

        if (startDifference < minDifference) {
          minDifference = startDifference;
          closestObject = obj;
        }
        if (endDifference < minDifference) {
          minDifference = endDifference;
          closestObject = obj;
        }
      });

      return closestObject;
    } else {
      return {};
    }
  };

  const initProjectWorker = () => {
    let projectWorkerList = [];
    userMeta.forEach((meta) => {
      let getProjectID = _optionalChain([projectMeta, 'optionalAccess', _4 => _4.find, 'call', _5 => _5((project) => {
        return _optionalChain([project, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("Vertrag"), 'access', _8 => _8.id]) === meta.id;
      })]);
      if (_optionalChain([getProjectID, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("Projekt"), 'access', _11 => _11.id]) === _optionalChain([currentProject, 'optionalAccess', _12 => _12.id])) {
        console.log("User: ", meta.get("User").get("name"));
        projectWorkerList.push(meta.get("User"));
      }
    });
    console.log("ProjectWorker", projectWorkerList);
    setProjectWorker(projectWorkerList);
  };

  const getTimeTrackingOfUserInQuarter = async (
    worker,
    quarter,
    nextquarter
  ) => {
    const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
    query.equalTo("User", worker);
    query.greaterThanOrEqualTo("Date", quarter);
    query.lessThan("Date", nextquarter);
    let results = await query.find();
    return results;
  };

  const getTimeTrackingOfUserBeforeQuarterInYear = async (
    worker,
    quarter
  ) => {
    const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
    query.equalTo("User", worker);
    query.greaterThanOrEqualTo("Date", dayjs(quarter).startOf("year").toDate());
    query.lessThan("Date", quarter);
    let results = await query.find();
    return results;
  };

  const calculateQuarterPerPerson = async () => {
    const personenKostenList = [];
    const otherKostenList = [];

    let oldCosts = 0;
    let newCosts = 0;
    let oldCostsOther = 0;
    let newCostsOther = 0;

    for (const worker of projectWorker) {
      //Calculate Yearly Costs
      let yearlyCosts = 0;
      let specials = false;
      let aquivalent = 0;
      let overhead = true;
      let ignore = false;
      userMeta.forEach((meta) => {
        if (meta.get("User").id === worker.id) {
          let getProjectID = _optionalChain([projectMeta, 'optionalAccess', _13 => _13.find, 'call', _14 => _14((project) => {
            return _optionalChain([project, 'optionalAccess', _15 => _15.get, 'call', _16 => _16("Vertrag"), 'access', _17 => _17.id]) === meta.id;
          })]);

          yearlyCosts +=
            Math.round(_optionalChain([getProjectID, 'optionalAccess', _18 => _18.get, 'call', _19 => _19("Monatsgehalt")]) * 12 * 100) / 100;
          if (meta.get("Status") != "Vollzeit") {
            specials = true;
            aquivalent = 40 / (meta.get("HoursADay") * 5);
          }
          if (meta.get("Status") === "Aushilfe ohne Overhead") {
            overhead = false;
          }
          if (meta.get("Status") === "Werksstudent") {
            ignore = true;
            overhead = false;
          }
        }
      });
      if (!ignore) {
        //Calculate Cost Rate of Project
        let yearlyHours = _optionalChain([currentProject, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("Jahresstunden")]);
        if (specials) yearlyHours = yearlyHours / aquivalent;
        let costRate = Math.round((yearlyCosts / yearlyHours) * 100) / 100;

        //Calculate Costs per Quarter
        let timeTrackings = await getTimeTrackingOfUserInQuarter(
          worker,
          generatedQuarters[selectedQuarter],
          generatedQuarters[selectedQuarter + 1]
        );
        let workingHoursInQuarter = 0;
        timeTrackings.forEach((timeTracking) => {
          workingHoursInQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        //get TimeTracking of User before Quarter in Year
        let timeTrackingsOld = await getTimeTrackingOfUserBeforeQuarterInYear(
          worker,
          generatedQuarters[selectedQuarter]
        );
        let workingHoursInOldQuarter = 0;
        timeTrackingsOld.forEach((timeTracking) => {
          workingHoursInOldQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        const monthlyTotals = {};

        // Durchlaufen der Daten und Berechnung der Summe für jeden Monat
        timeTrackings.forEach((item) => {
          const month = item.get("Date").getMonth();
          const key = `${month + 1}.${item.get("Date").getFullYear()}`; // Monat.Jahr als Schlüssel
          const product =
            item.get("WorkingHours") * (item.get("Project") / 100);

          // Hinzufügen zum entsprechenden Monat in den Ergebnissen
          if (!monthlyTotals[key]) {
            monthlyTotals[key] = product;
          } else {
            monthlyTotals[key] += product;
          }
        });

        let personenKosten = {};
        if (!overhead) {
          personenKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead: workingHoursInQuarter * costRate,
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) * costRate,
          };
          newCosts +=
            (workingHoursInQuarter + workingHoursInOldQuarter) * costRate;
          oldCosts += workingHoursInOldQuarter * costRate;
        } else {
          personenKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead:
              workingHoursInQuarter *
              costRate *
              (1 + _optionalChain([currentProject, 'optionalAccess', _22 => _22.get, 'call', _23 => _23("Overhead")]) / 100),
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) *
              costRate *
              (1 + _optionalChain([currentProject, 'optionalAccess', _24 => _24.get, 'call', _25 => _25("Overhead")]) / 100),
          };
          newCosts +=
            (workingHoursInQuarter + workingHoursInOldQuarter) *
            costRate *
            (1 + _optionalChain([currentProject, 'optionalAccess', _26 => _26.get, 'call', _27 => _27("Overhead")]) / 100);
          oldCosts +=
            workingHoursInOldQuarter *
            costRate *
            (1 + _optionalChain([currentProject, 'optionalAccess', _28 => _28.get, 'call', _29 => _29("Overhead")]) / 100);
        }
        personenKostenList.push(personenKosten);
      } else {
        //Calculate Cost Rate of Project
        let yearlyHours = _optionalChain([currentProject, 'optionalAccess', _30 => _30.get, 'call', _31 => _31("Jahresstunden")]);
        if (specials) yearlyHours = yearlyHours / aquivalent;
        let costRate = Math.round((yearlyCosts / yearlyHours) * 100) / 100;

        //Calculate Costs per Quarter
        let timeTrackings = await getTimeTrackingOfUserInQuarter(
          worker,
          generatedQuarters[selectedQuarter],
          generatedQuarters[selectedQuarter + 1]
        );
        let workingHoursInQuarter = 0;
        timeTrackings.forEach((timeTracking) => {
          workingHoursInQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        //get TimeTracking of User before Quarter in Year
        let timeTrackingsOld = await getTimeTrackingOfUserBeforeQuarterInYear(
          worker,
          generatedQuarters[selectedQuarter]
        );
        let workingHoursInOldQuarter = 0;
        timeTrackingsOld.forEach((timeTracking) => {
          workingHoursInOldQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        const monthlyTotals = {};

        // Durchlaufen der Daten und Berechnung der Summe für jeden Monat
        timeTrackings.forEach((item) => {
          const month = item.get("Date").getMonth();
          const key = `${month + 1}.${item.get("Date").getFullYear()}`; // Monat.Jahr als Schlüssel
          const product =
            item.get("WorkingHours") * (item.get("Project") / 100);

          // Hinzufügen zum entsprechenden Monat in den Ergebnissen
          if (!monthlyTotals[key]) {
            monthlyTotals[key] = product;
          } else {
            monthlyTotals[key] += product;
          }
        });

        let otherKosten = {};
        if (!overhead) {
          otherKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead: workingHoursInQuarter * costRate,
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) * costRate,
          };
          newCostsOther +=
            (workingHoursInQuarter + workingHoursInOldQuarter) * costRate;
          oldCostsOther += workingHoursInOldQuarter * costRate;
        } else {
          otherKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead:
              workingHoursInQuarter *
              costRate *
              (1 + _optionalChain([currentProject, 'optionalAccess', _32 => _32.get, 'call', _33 => _33("Overhead")]) / 100),
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) *
              costRate *
              (1 + _optionalChain([currentProject, 'optionalAccess', _34 => _34.get, 'call', _35 => _35("Overhead")]) / 100),
          };
          newCostsOther +=
            (workingHoursInQuarter + workingHoursInOldQuarter) *
            costRate *
            (1 + _optionalChain([currentProject, 'optionalAccess', _36 => _36.get, 'call', _37 => _37("Overhead")]) / 100);
          oldCostsOther +=
            workingHoursInOldQuarter *
            costRate *
            (1 + _optionalChain([currentProject, 'optionalAccess', _38 => _38.get, 'call', _39 => _39("Overhead")]) / 100);
        }
        otherKostenList.push(otherKosten);
      }
    }
    console.log("--------------------------------");
    console.log(personenKostenList);
    setPersonalkosten(personenKostenList);
    setOtherkosten(otherKostenList);
    setPersonalkostenNumber([newCosts, oldCosts]);
    setOtherkostenNumber([newCostsOther, oldCostsOther]);
  };

  const personalKostenTableData = async () => {
    const data = [];
    if (selectedQuarter != -1) {
      personalKosten.forEach((item) => {
        console.log("ITEM", JSON.stringify(item));
        const month1 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][0].startsWith("0")
                ? monthsInQuarter[selectedQuarter][0].slice(1)
                : monthsInQuarter[selectedQuarter][0]
            }`
          ] || 0;
        const month2 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][1].startsWith("0")
                ? monthsInQuarter[selectedQuarter][1].slice(1)
                : monthsInQuarter[selectedQuarter][1]
            }`
          ] || 0;
        const month3 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][2].startsWith("0")
                ? monthsInQuarter[selectedQuarter][2].slice(1)
                : monthsInQuarter[selectedQuarter][2]
            }`
          ] || 0;
        const sum = item.workingHoursInQuarter;
        data.push({
          key: item.name + Math.random() + Math.random() + "",
          name: item.name,
          month1:
            month1.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month2:
            month2.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month3:
            month3.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          sum:
            sum.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          yearcosts:
            item.yearCosts.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          yearhours: item.yearHours.toLocaleString("de-DE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
          costRate:
            item.costRate.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €/Std.",
          costsInQuarter:
            item.costsInQuarter.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          costsInQuarterWithOverhead:
            item.costsInQuarterWithOverhead.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        });
      });
    }
    setPersonalkostenTable(data);
  };

  const otherKostenTableData = async () => {
    const data = [];

    if (selectedQuarter != -1) {
      otherKosten.forEach((item) => {
        const month1 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][0].startsWith("0")
                ? monthsInQuarter[selectedQuarter][0].slice(1)
                : monthsInQuarter[selectedQuarter][0]
            }`
          ] || 0;
        const month2 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][1].startsWith("0")
                ? monthsInQuarter[selectedQuarter][1].slice(1)
                : monthsInQuarter[selectedQuarter][1]
            }`
          ] || 0;
        const month3 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][2].startsWith("0")
                ? monthsInQuarter[selectedQuarter][2].slice(1)
                : monthsInQuarter[selectedQuarter][2]
            }`
          ] || 0;
        const sum = item.workingHoursInQuarter;
        data.push({
          key: item.name,
          name: item.name,
          month1:
            month1.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month2:
            month2.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month3:
            month3.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          sum:
            sum.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          yearcosts:
            item.yearCosts.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          yearhours: item.yearHours.toLocaleString("de-DE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
          costRate:
            item.costRate.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €/Std.",
          costsInQuarter:
            item.costsInQuarter.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          costsInQuarterWithOverhead:
            item.costsInQuarterWithOverhead.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        });
      });
    }
    setOtherkostenTable(data);
  };

  const materialKostenTableData = async (quarter, nextquarter) => {
    const data = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0813");
    query.greaterThanOrEqualTo("InvoiceDate", startOfYear);
    query.lessThan(
      "InvoiceDate",
      nextquarter > endOfYear ? endOfYear : nextquarter
    );
    let results = await query.find();
    let oldMatCosts = 0;
    let newMatCosts = 0;
    results.forEach((item) => {
      if (item.get("InvoiceDate") < quarter) {
        oldMatCosts += item.get("Netto");
        newMatCosts += item.get("Netto");
      } else {
        newMatCosts += item.get("Netto");
        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          invoice: _optionalChain([item, 'access', _40 => _40.get, 'call', _41 => _41("Invoice"), 'optionalAccess', _42 => _42.url, 'call', _43 => _43()]) || "",
          duration: "0",
          monthcosts: 0,
        });
      }
    });
    setMaterialkostenTable(data);
    setMaterialkosten([newMatCosts, oldMatCosts]);
  };

  const investKostenTableData = async (quarter) => {
    let oldInvestCosts = 0;
    let newInvestCosts = 0;
    const data = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0847");
    query.lessThanOrEqualTo("InvoiceDate", quarter);
    let results = await query.find();

    results.forEach((item) => {
      let datePuffer = dayjs(item.get("InvoiceDate"))
        .endOf("month")
        .add(item.get("Duration") - 1, "month");

      const start = dayjs(item.get("InvoiceDate")).startOf("month");
      const end = datePuffer;
      let count = 0;

      monthsInQuarter[selectedQuarter].forEach((monthString) => {
        const [month, year] = monthString.split(".");
        const monthDate = dayjs(`${year}-${month}-01`);

        if (
          (monthDate.isSame(start, "month") ||
            monthDate.isAfter(start, "month")) &&
          (monthDate.isSame(end, "month") || monthDate.isBefore(end, "month"))
        ) {
          count++;
        }
      });

      if (count > 0) {
        const [month, year] = monthsInQuarter[selectedQuarter][0].split(".");
        const startOfMonth = dayjs(`${year}-${month}-01`);
        const current = startOfMonth;
        let oldCount = 0;

        for (let month = 1; month < current.month() + 1; month++) {
          const monthDate = dayjs(
            `${current.year()}-${String(month).padStart(2, "0")}-01`
          );
          if (
            (monthDate.isSame(start, "month") ||
              monthDate.isAfter(start, "month")) &&
            (monthDate.isSame(end, "month") || monthDate.isBefore(end, "month"))
          ) {
            oldCount++;
          }
        }
        oldInvestCosts =
          (Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100) *
          oldCount;

        newInvestCosts =
          oldInvestCosts +
          (Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100) *
            count;

        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          duration: item.get("Duration"),
          monthcosts:
            Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100,
          invoice: _optionalChain([item, 'access', _44 => _44.get, 'call', _45 => _45("Invoice"), 'optionalAccess', _46 => _46.url, 'call', _47 => _47()]) || "",
        });
      }
    });
    setInvestkostenTable(data);
    setInvestkosten([newInvestCosts, oldInvestCosts]);
  };

  const rdKostenTableData = async (quarter, nextquarter) => {
    const data = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0823");
    query.greaterThanOrEqualTo("InvoiceDate", startOfYear);
    query.lessThan(
      "InvoiceDate",
      nextquarter > endOfYear ? endOfYear : nextquarter
    );
    let results = await query.find();
    let oldMatCosts = 0;
    let newMatCosts = 0;
    results.forEach((item) => {
      if (item.get("InvoiceDate") < quarter) {
        oldMatCosts += item.get("Netto");
        newMatCosts += item.get("Netto");
      } else {
        newMatCosts += item.get("Netto");
        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          invoice: _optionalChain([item, 'access', _48 => _48.get, 'call', _49 => _49("Invoice"), 'optionalAccess', _50 => _50.url, 'call', _51 => _51()]) || "",
          duration: "0",
          monthcosts: 0,
        });
      }
    });
    setRDkostenTable(data);
    setRDkosten([newMatCosts, oldMatCosts]);
  };

  const travelKostenTableData = async (quarter, nextquarter) => {
    const data = [];
    const query = new Parse.Query("OD3_Trip").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.greaterThanOrEqualTo("End", startOfYear);
    query.lessThan("End", nextquarter > endOfYear ? endOfYear : nextquarter);
    let results = await query.find();
    let oldTravelCosts = 0;
    let newTravelCosts = 0;
    results.forEach((item) => {
      if (item.get("End") < quarter) {
        oldTravelCosts += item.get("Costs");
        newTravelCosts += item.get("Costs");
      } else {
        newTravelCosts += item.get("Costs");
        data.push({
          key: item.id,
          start: item.get("Start"),
          end: item.get("End"),
          employee: item.get("Employee").get("name"),
          description: item.get("Description"),
          costs: item.get("Costs"),
          documents: _optionalChain([item, 'access', _52 => _52.get, 'call', _53 => _53("Documents"), 'optionalAccess', _54 => _54.url, 'call', _55 => _55()]) || "",
        });
      }
    });
    setTripkostenTable(data);
    setTripkosten([newTravelCosts, oldTravelCosts]);
  };

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 947}}
      , React.createElement(AdminToolbar, {
        title: "Mittelabruf",
        description: "Planung und Berechnung für den Mittelabruf von Forschungsprojekten."       ,
        search: "",
        actions: [
          React.createElement(Select, {
            key: "1",
            value: selectedQuarter,
            onChange: (e) => {
              setSelectedQuarter(e);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 953}}

            , generatedQuarters.map((quarter, index) => (
              React.createElement(Select.Option, { key: index, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 961}}
                , `Q${
                  Math.floor(quarter.getMonth() / 3) + 1
                }/${_optionalChain([quarter, 'optionalAccess', _56 => _56.getFullYear, 'call', _57 => _57()])}`
              )
            ))
          ),
          React.createElement(Select, {
            key: "2",
            value: _optionalChain([currentProject, 'optionalAccess', _58 => _58.get, 'call', _59 => _59("Name")]),
            style: { width: 200 },
            onChange: (e) => {
              setCurrentProject(projectData[e]);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 968}}

            , projectData.map((project, index) => (
              React.createElement(Select.Option, { key: project.id, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 977}}
                , project.get("Name")
              )
            ))
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 948}}
      )

      , loading && (
        React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 986}}

          , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 996}} )
        )
      )
      , !loading && (
        React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1000}}

          , React.createElement(Space, { direction: "vertical", size: "large", style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1006}}
            , React.createElement(Space, {
              direction: "vertical",
              size: "small",
              style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1007}}

              , React.createElement(Title, { level: 4, style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1012}}, "Projektzeitleiste"

              )

              , React.createElement(Timeline, {
                start: _optionalChain([currentProject, 'optionalAccess', _60 => _60.get, 'call', _61 => _61("Start")]),
                end: _optionalChain([currentProject, 'optionalAccess', _62 => _62.get, 'call', _63 => _63("End")]),
                today: new Date(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 1016}}
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 1022}} )
            , React.createElement(Title, { level: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1023}}, "Mittelabruf")
            , React.createElement('div', {
              style: {
                width: "100%",
                display: "flex",
                paddingLeft: "25px",
                paddingRight: "25px",
                justifyContent: "center",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1024}}

              , React.createElement(ProjectRequest, {
                personalKosten: personalKosten,
                otherKosten: otherKosten,
                materialKosten: materialKosten,
                tripKosten: tripKosten,
                rdKosten: rdKosten,
                investKosten: investKosten,
                quarterList: generatedQuarters[selectedQuarter],
                currentProject: currentProject,
                monthsInQuarter: monthsInQuarter,
                selectedQuarter: selectedQuarter,
                overAllCosts: overAllCosts, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1033}}
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 1047}} )
            , React.createElement(Title, { level: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1048}}, "Details")

            , React.createElement(Collapse, { defaultActiveKey: ["1"], onChange: () => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1050}}
              , React.createElement(Panel, { header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1051}}, "0813 Material" ), key: "22", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1051}}
                , React.createElement(ProjectInvest, {
                  dataSource: materialKostenTable,
                  invest: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1052}}
                )
              )
              , React.createElement(Panel, {
                header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1058}}, "0823 FE-Fremdleistung" ),
                key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1057}}

                , React.createElement(ProjectInvest, { dataSource: rdKostenTable, invest: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1061}} )
              )
              , React.createElement(Panel, {
                header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1064}}, "0837 Personalkosten" ),
                key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1063}}

                , React.createElement(ProjectPersonal, {
                  dataSource: personalKostenTable,
                  currentQuarter: monthsInQuarter[selectedQuarter], __self: this, __source: {fileName: _jsxFileName, lineNumber: 1067}}
                )
              )
              , React.createElement(Panel, {
                header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1073}}, "0838 Reisekosten" ),
                key: "32", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1072}}

                , React.createElement(ProjectTrip, { dataSource: tripKostenTable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1076}} )
              )
              , React.createElement(Panel, {
                header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1079}}, "0847 Abschreibungen" ),
                key: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1078}}

                , React.createElement(ProjectInvest, { dataSource: investKostenTable, invest: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1082}} )
              )
              , React.createElement(Panel, {
                header: React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1085}}, "0850 Sonstige Kosten"  ),
                key: "5", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1084}}

                , React.createElement(ProjectPersonal, {
                  dataSource: otherKostenTable,
                  currentQuarter: monthsInQuarter[selectedQuarter], __self: this, __source: {fileName: _jsxFileName, lineNumber: 1088}}
                )
              )
            )
          )
        )
      )
    )
  );
});
