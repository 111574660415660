const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/Pieces/VacationTable.tsx";import React from "react";
import dayjs from "dayjs";

import { Table, Tag, Button, Popconfirm } from "antd";
import { Icon } from "@opendash/icons";







export const VacationTable = ({
  data,
  deleteForm,
}) => {
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    formatTableData();
  }, [data]);

  const formatTableData = () => {
    let tableData = [];
    data.forEach((element) => {
      let data = {
        key: element.id,
        name: element.get("Type"),
        start: element.get("Start"),
        end: element.get("End"),
        days: element.get("Days"),
        status: element.get("Status"),
      };
      tableData.push(data);
    });
    setTableData(tableData);
  };

  








  const columns = [
    {
      title: "Typ",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Start",
      dataIndex: "start",
      align: "center",
      render: (_, record) => {
        return React.createElement(React.Fragment, null, dayjs(record.start).format("DD.MM.YYYY"));
      },
    },
    {
      title: "Ende",
      dataIndex: "end",
      align: "center",
      render: (_, record) => {
        return React.createElement(React.Fragment, null, dayjs(record.end).format("DD.MM.YYYY"));
      },
    },
    {
      title: "Tage",
      dataIndex: "days",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (_, record) => {
        return (
          React.createElement(Tag, {
            color: 
              record.status === 0
                ? "#2db7f5"
                : record.status === 1
                ? "#87d068"
                : "#f50"
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}

            , record.status === 0
              ? "Beantragt"
              : record.status === 1
              ? "Genehmigt"
              : "Abgelehnt"
          )
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      align: "center",
      render: (_, record) => {
        return (
          React.createElement(Popconfirm, {
            title: "Antrag löschen?" ,
            disabled: record.status != 0,
            onConfirm: (e) => {
              deleteForm(record);
            },
            onCancel: (e) => {},
            okText: "Löschen",
            cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}

            , React.createElement(Button, {
              icon: React.createElement(Icon, { icon: "fa:trash", key: "delete", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}} ),
              danger: true,
              disabled: record.status != 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
            )
          )
        );
      },
    },
  ];

  return React.createElement(Table, { columns: columns, dataSource: tableData, size: "small", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}} );
};
