const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/AdminPages/VacationCheckComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import {
  Table,
  Select,
  Button,
  Popconfirm,
  Card,
  Space,
  message,
  Spin,
} from "antd";
import { Icon } from "@opendash/icons";
import { createComponent } from "@opendash/state";

const { Option } = Select;
const { Meta } = Card;

export const VacationCheckComponent = createComponent(
  function VacationCheckComponentComponent() {
    const [tableData, setTableData] = React.useState([]);
    const [parseData, setParseData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [userMeta, setUserMeta] = React.useState([]);
    const [freeDays, setFreeDays] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
      initUserData();
      initUserMeta();
      getFreeDays();
      getParseData();
    }, []);

    React.useEffect(() => {
      formatTableData();
    }, [parseData]);

    const initUserData = async () => {
      try {
        const query = new Parse.Query("_User").limit(99999999);
        query.equalTo("VacationWithoutApplication", false);
        const result = await query.find();
        setUserData(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    const initUserMeta = async () => {
      try {
        const query = new Parse.Query("OD3_Contract").limit(99999999);
        query.include("User");
        const result = await query.find();
        setUserMeta(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    const getMetaOfUser = (User) => {
      let foundObject = userMeta.find(
        (obj) => obj.get("User").id === User.id
      );
      return foundObject;
    };

    const getParseData = async () => {
      try {
        const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
        query.descending("createdAt");
        const result = await query.find();
        if (result && result.length > 0) {
          setParseData(result);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-Daten:", error);
      }
    };

    const getFreeDays = async () => {
      try {
        const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(
          99999999
        );
        const result = await query.find();
        setFreeDays(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der freien Tage:", error);
      }
    };

    const calcUsedVacation = (id) => {
      let usedVacationDays = 0;
      if (parseData.length > 0) {
        let foundObject = parseData.filter(
          (obj) => obj.get("User").id === id
        );
        if (foundObject && foundObject.length > 0) {
          foundObject.forEach((element) => {
            if (element.get("Status") === 1) {
              usedVacationDays += element.get("Days");
            }
          });
        }
      }
      return usedVacationDays;
    };

    const editForm = async (form, e) => {
      if (e === 1) {
        // Add all Relations
        setLoading(true);
        try {
          const UserClass = Parse.Object.extend("_User");
          const userObject = new UserClass({ id: form.userid });
          await userObject.fetch();
          const query = new Parse.Query("OD3_Contract").limit(99999999);
          query.equalTo("User", userObject);
          let results = await query.find();
          const filteredObjects = results.find((obj) => {
            const startDate = obj.get("Start");
            const endDate = obj.get("End");
            return (
              (form.start >= startDate && form.end <= endDate) ||
              form.start.toDateString() === startDate.toDateString() ||
              form.end.toDateString() === endDate.toDateString()
            );
          });

          for (
            let date = form.start;
            date <= form.end;
            date.setDate(date.getDate() + 1)
          ) {
            if (date.getDay() != 0 && date.getDay() != 6) {
              //Delete Feiertage
              let foundObject = freeDays.find(
                (obj) =>
                  dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
                  dayjs(date).format("DD.MM.YYYY")
              );
              if (!foundObject) {
                const timeTrackingObject = new Parse.Object("OD3_TimeTracking");

                timeTrackingObject.set(
                  "Date",
                  new Date(dayjs(date).startOf("day").toDate())
                );
                timeTrackingObject.set(
                  "WorkingHours",
                  _optionalChain([filteredObjects, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("HoursADay")])
                );
                timeTrackingObject.set("Comment", "Urlaubsantrag");
                timeTrackingObject.set("Sick", false);
                timeTrackingObject.set("Vacation", true);
                timeTrackingObject.set("Project", 0);
                timeTrackingObject.set("User", userObject);

                const acl = new Parse.ACL();
                const currentUserACL = userObject;
                if (currentUserACL) {
                  acl.setReadAccess(currentUserACL, true);
                  acl.setWriteAccess(currentUserACL, true);
                }
                acl.setRoleReadAccess(
                  "od-tenant-admin-" + _optionalChain([$parse, 'access', _4 => _4.user, 'access', _5 => _5.current, 'call', _6 => _6(), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("tenant"), 'optionalAccess', _9 => _9.id]),
                  true
                );
                acl.setRoleWriteAccess(
                  "od-tenant-admin-" + _optionalChain([$parse, 'access', _10 => _10.user, 'access', _11 => _11.current, 'call', _12 => _12(), 'optionalAccess', _13 => _13.get, 'call', _14 => _14("tenant"), 'optionalAccess', _15 => _15.id]),
                  true
                );
                timeTrackingObject.setACL(acl);
                await timeTrackingObject.save().then(async (timetracking) => {
                  const VacationClass = Parse.Object.extend("OD3_TimeVacation");
                  const toSave = new VacationClass({ id: form.key });
                  try {
                    await toSave.fetch();
                    await toSave.save().then(async (e) => {
                      var relation = e.relation("TimeTracks");
                      relation.add(timetracking);
                      await e.save();
                    });
                  } catch (error) {
                    console.error(
                      "Fehler beim Editieren des Eintrags1:",
                      error
                    );
                    return false;
                  }
                });
              }
            }
          }
        } catch (error) {
          console.error(
            "Fehler beim Anlagen der Einträge der Einträge:",
            error
          );
          return false;
        }

        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            toSave.set("Status", e);
            await toSave.save().then(async (x) => {
              await x.save();
              await getParseData();
              setLoading(false);
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          return false;
        }
      } else {
        //Delete all Relations
        setLoading(true);
        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            await toSave.save().then(async (x) => {
              var relation = x.relation("TimeTracks");
              relation
                .query()
                .find()
                .then(async (results) => {
                  results.forEach(async (element) => {
                    await element.destroy();
                  });
                  await x.save();
                });
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          return false;
        }
        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            toSave.set("Status", e);
            await toSave.save().then(async (x) => {
              await x.save();
              await getParseData();
              setLoading(false);
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          return false;
        }
      }
      return true;
    };

    const deleteForm = async (form) => {
      setLoading(true);
      try {
        const VacationClass = Parse.Object.extend("OD3_TimeVacation");
        const toSave = new VacationClass({ id: form.key });
        try {
          await toSave.fetch();
          await toSave.save().then(async (x) => {
            var relation = x.relation("TimeTracks");
            relation
              .query()
              .find()
              .then(async (results) => {
                results.forEach(async (element) => {
                  await element.destroy();
                });
                await x.save();
                getParseData();
              });
          });
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags1:", error);
          return false;
        }
      } catch (error) {
        console.error("Fehler beim Editieren des Eintrags2:", error);
        return false;
      }

      try {
        const VacationClass = Parse.Object.extend("OD3_TimeVacation");
        let toDelete = await VacationClass.createWithoutData(form.key).fetch();
        await toDelete.destroy();
        await getParseData();
        setLoading(false);
      } catch (error) {
        console.error("Fehler beim Löschen des Eintrags:", error);
      }
    };

    const formatTableData = () => {
      if (parseData.length > 0) {
        let tableData = [];
        parseData.forEach((element) => {
          let data = {
            key: element.id,
            userid: element.get("User").id,
            name: element.get("User").get("name"),
            type: element.get("Type"),
            start: element.get("Start"),
            end: element.get("End"),
            days: element.get("Days"),
            rest:
              element.get("User").get("VacationDays") -
              calcUsedVacation(element.get("User").id),
            status: element.get("Status"),
          };
          tableData.push(data);
        });
        setTableData(tableData);
      }
    };

    










    const columns = [
      {
        title: "Mitarbeiter",
        dataIndex: "name",
        align: "center",
      },
      {
        title: "Typ",
        dataIndex: "type",
        align: "center",
      },
      {
        title: "Start",
        dataIndex: "start",
        align: "center",
        render: (_, record) => {
          return React.createElement(React.Fragment, null, dayjs(record.start).format("DD.MM.YYYY"));
        },
      },
      {
        title: "Ende",
        dataIndex: "end",
        align: "center",
        render: (_, record) => {
          return React.createElement(React.Fragment, null, dayjs(record.end).format("DD.MM.YYYY"));
        },
      },
      {
        title: "Urlaubstage",
        dataIndex: "days",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        render: (_, record) => {
          return (
            React.createElement(Select, {
              value: record.status,
              onChange: (e) => {
                editForm(record, e);
                message.success("Urlaubsantrag wurde erfolgreich geändert!");
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 390}}

              , React.createElement(Select.Option, { value: 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}}, "Beantragt")
              , React.createElement(Select.Option, { value: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 398}}, "Genehmigt")
              , React.createElement(Select.Option, { value: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}, "Abgelehnt")
            )
          );
        },
      },
      {
        title: "",
        dataIndex: "delete",
        align: "center",
        render: (_, record) => {
          return (
            React.createElement(Popconfirm, {
              title: "Antrag löschen?" ,
              onConfirm: (e) => {
                deleteForm(record);
              },
              onCancel: (e) => {},
              okText: "Löschen",
              cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 410}}

              , React.createElement(Button, { icon: React.createElement(Icon, { icon: "fa:trash", key: "delete", __self: this, __source: {fileName: _jsxFileName, lineNumber: 419}} ), danger: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 419}} )
            )
          );
        },
      },
    ];

    const gridStyle = {
      width: "33.33333%",
      textAlign: "center",
    };

    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 432}}
        , React.createElement(AdminToolbar, {
          title: "Urlaubsverwaltung",
          description: "Urlaubsanträge bearbeiten und genehmigen."   ,
          search: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 433}}
        )
        , React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 438}}

          , React.createElement(Space, { direction: "vertical", size: "middle", style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 444}}
            , React.createElement(Card, { title: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 445}}
              , userData.map((user, index) => (
                React.createElement(Card.Grid, { style: gridStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 447}}
                  , React.createElement(Meta, {
                    title: user.get("name"),
                    description: 
                      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 451}}, "Gesamter Urlaubsanspruch:"
                         , " "
                        , _optionalChain([getMetaOfUser, 'call', _16 => _16(user), 'optionalAccess', _17 => _17.get, 'call', _18 => _18("VacationDays")])
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 454}} ), "Verbleibender Urlaub:"
                         , " "
                        , _optionalChain([getMetaOfUser, 'call', _19 => _19(user), 'optionalAccess', _20 => _20.get, 'call', _21 => _21("VacationDays")]) -
                          calcUsedVacation(user.id)
                      )
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 448}}
                  )
                )
              ))
            )
            , loading && (
              React.createElement('div', {
                className: "od-page-main",
                style: {
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 465}}

                , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 475}} )
              )
            )
            , !loading && (
              React.createElement(Table, { columns: columns, dataSource: tableData, size: "small", __self: this, __source: {fileName: _jsxFileName, lineNumber: 479}} )
            )
          )
        )
      )
    );
  }
);
