const _jsxFileName = "/usr/src/app/src/js/components/timetrack/src/components/TimeComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";

import { AdminToolbar } from "@opendash/ui";
import { $parse } from "@opendash/plugin-parse";
import Parse from "parse";
import { EntryModal } from "./Pieces/EntryModal";
import {
  Row,
  Select,
  Col,
  Button,
  Tooltip,
  Spin,
  message,
  Statistic,
} from "antd";

import dayjs from "dayjs";
import { CalendarView } from "./Pieces/CalendarView";
import { AlertView } from "./Pieces/AlertView";

const informationText = `
Gemäß dem Arbeitszeitgesetz gelten folgende Regeln:
Die tägliche Arbeitszeit darf 10 Stunden nicht überschreiten.
Zwischen zwei Arbeitstagen muss eine ununterbrochene Ruhezeit von mindestens 11 Stunden liegen.
Nach 6 Stunden Arbeit ist eine Pause von mindestens 30 Minuten vorgeschrieben.
Nach 8 Stunden Arbeit ist eine Pause von mindestens 45 Minuten vorgeschrieben.
Die maximale wöchentliche Arbeitszeit beträgt 48 Stunden, einschließlich Überstunden.
`;

export const TimeComponent = observer((props) => {
  const [currentUser, setCurrentUser] = React.useState(
    Parse.User.current()
  );
  const [adminMode, setAdminMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [daysEntered, setDaysEntered] = React.useState(0);
  const [leaveDays, setLeaveChecked] = React.useState(0);
  const [totalProjectWorked, setTotalProjectWorked] = React.useState(0);
  const [maxProjectWorked, setMaxProjectWorked] = React.useState(0);
  const [totalWorked, setTotalWorked] = React.useState(0);
  const [shouldWorked, setShouldWorked] = React.useState(0);
  const [parseData, setParseData] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [userMeta, setUserMeta] = React.useState(undefined);
  const [projectMeta, setProjectMeta] = React.useState(undefined);
  const [freeDays, setFreeDays] = React.useState([]);
  const [value, setValue] = React.useState(() => dayjs());
  const [selectedMonth, setSelectedMonth] = React.useState(
    dayjs().month()
  );
  const [selectedYear, setSelectedYear] = React.useState(
    dayjs().year()
  );
  const [modalVisible, setModalVisible] = React.useState(false);
  const [hoursWorked, setHoursWorked] = React.useState(8);

  const [contractWorkingHours, setContractWorkingHours] = React.useState

(8);

  const [comment, setComment] = React.useState("");
  const [percentage, setPercentage] = React.useState(90);
  const [vacationChecked, setVacationChecked] = React.useState(false);
  const [sickLeaveChecked, setSickLeaveChecked] =
    React.useState(false);
  const onSelect = (newValue) => {
    setValue(newValue);
  };

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  React.useEffect(() => {
    initUserMeta();
    initUserData();
    getParseData();
    getFreeDays();
    calcTotalWorked();
    calcShouldWorked();
  }, []);

  React.useEffect(() => {
    initUserMeta();
    initUserData();
    getParseData();
    getFreeDays();
    calcTotalWorked();
    calcShouldWorked();
    calcTotalWorked();
    calcShouldWorked();
    calcMaxProjectWorkHours();
  }, [currentUser]);

  React.useEffect(() => {
    calcShouldWorked();
    calcTotalWorked();
    calcMaxProjectWorkHours();
  }, [userMeta, projectMeta, hoursWorked, contractWorkingHours]);

  React.useEffect(() => {
    initUserMeta();
  }, [selectedMonth, selectedYear, parseData]);

  React.useEffect(() => {
    calcShouldWorked();
    calcMaxProjectWorkHours();
  }, [selectedMonth, selectedYear, freeDays, leaveDays]);

  React.useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 450);
    return () => clearTimeout(timeout);
  }, [selectedMonth, selectedYear, currentUser]);

  const initUserMeta = () => {
    initProjectMeta();
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.equalTo("User", currentUser);
    query.include("Project");
    query.find().then((results) => {
      const filteredObjects = results.find((obj) => {
        const startDate = obj.get("Start");
        const endDate = obj.get("End");
        //const today = new Date();
        const today = new Date(selectedYear, selectedMonth, 15);
        return (
          (today >= startDate && today <= endDate) ||
          today.toDateString() === startDate.toDateString() ||
          today.toDateString() === endDate.toDateString()
        );
      });
      setUserMeta(filteredObjects);
      setHoursWorked(_optionalChain([filteredObjects, 'optionalAccess', _ => _.get, 'call', _2 => _2("HoursADay")]));
      setContractWorkingHours(_optionalChain([filteredObjects, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("HoursADay")]));
      let getProjectID = _optionalChain([projectMeta, 'optionalAccess', _5 => _5.find, 'call', _6 => _6((project) => {
        return _optionalChain([project, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("Vertrag"), 'access', _9 => _9.id]) === _optionalChain([filteredObjects, 'optionalAccess', _10 => _10.id]);
      })]);
      setPercentage(_optionalChain([getProjectID, 'optionalAccess', _11 => _11.get, 'call', _12 => _12("Projektanteil")]));
    });
  };

  const initProjectMeta = async () => {
    if (props.researchprojectPlugin) {
      //ProjectMeta
      const queryProjectMeta = new Parse.Query("OD3_ProjectContract").limit(
        99999999
      );
      queryProjectMeta.include("Vertrag");
      queryProjectMeta.include("Projekt");
      let resultsProject = await queryProjectMeta.find();
      setProjectMeta(resultsProject);
    } else {
      setProjectMeta([]);
    }
  };

  const initUserData = async () => {
    try {
      const query = new Parse.Query("_User").limit(99999999);
      const result = await query.find();

      //Add Admin to the list
      const adminObject = new Parse.Object("_User");
      adminObject.id = "admin";
      adminObject.set("name", "Admin");

      result.unshift(adminObject);

      setUserData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const getFreeDays = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(99999999);
      const result = await query.find();
      setFreeDays(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der freien Tage:", error);
    }
  };

  const getParseData = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      const result = await query.find();
      setParseData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const deleteCurrentEntry = async (entry) => {
    try {
      await entry.destroy();
      await getParseData();

      await calcTotalWorked();
      await calcShouldWorked();
    } catch (error) {
      console.error("Fehler beim Löschen des Eintrags:", error);
    }
  };

  const calcTotalWorked = () => {
    let leaveDays = 0;
    let projectHours = 0;
    let calcedWorkHour = 0;
    let foundObject = parseData.filter(
      (obj) =>
        dayjs(obj.get("Date")).format("MM.YYYY") ===
        dayjs(
          `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, "0")}`,
          "YYYY-MM"
        ).format("MM.YYYY")
    );
    if (foundObject && foundObject.length > 0) {
      let onlyUserItems = foundObject.filter(
        (obj) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _13 => _13.id])
      );
      if (onlyUserItems && onlyUserItems.length > 0) {
        setDaysEntered(onlyUserItems.length);
        onlyUserItems.forEach((element) => {
          if (element.get("Sick") || element.get("Vacation")) {
            leaveDays++;
            calcedWorkHour += contractWorkingHours || 8;
          } else {
            calcedWorkHour += element.get("WorkingHours");
            projectHours +=
              (element.get("WorkingHours") / 100) * element.get("Project");
          }
        });
      }
    }
    setLeaveChecked(leaveDays);
    setTotalProjectWorked(Math.round(projectHours * 100) / 100);
    setTotalWorked(Math.round(calcedWorkHour * 100) / 100);
  };

  const calcMaxProjectWorkHours = () => {
    let days = anzahlWerktagen(selectedMonth + 1, selectedYear);
    let hoursADay = contractWorkingHours || 8;
    setMaxProjectWorked(
      Math.round((((days - leaveDays) * hoursADay * percentage) / 100) * 100) /
        100
    );
  };

  const calcShouldWorked = () => {
    if (freeDays.length > 0) {
      let days = anzahlWerktagen(selectedMonth + 1, selectedYear);
      let hoursADay = contractWorkingHours || 8;
      setShouldWorked(Math.round(days * hoursADay * 100) / 100);
    }
  };

  const anzahlWerktagen = (monat, jahr) => {
    let werktage = 0;
    const ersteTagDesMonats = dayjs(
      `${jahr}-${monat.toString().padStart(2, "0")}-01`,
      "YYYY-MM-DD"
    );
    const letzterTagDesMonats = ersteTagDesMonats
      .add(1, "month")
      .subtract(1, "day"); // Letzter Tag des Monats
    for (let tag = 1; tag <= letzterTagDesMonats.date(); tag++) {
      const datum = dayjs(
        `${jahr}-${monat.toString().padStart(2, "0")}-${tag
          .toString()
          .padStart(2, "0")}`,
        "YYYY-MM-DD"
      );
      if (datum.day() > 0 && datum.day() < 6) {
        // 0 steht für Sonntag und 6 für Samstag

        //Delete Feiertage
        let foundObject = freeDays.find(
          (obj



) =>
            dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
            datum.format("DD.MM.YYYY")
        );

        if (foundObject) {
        } else {
          werktage++;
        }
      }
    }
    return werktage;
  };

  const getYearOptions = () => {
    const currentYear = dayjs().year();
    const options = [];
    for (let i = currentYear - 1; i < currentYear + 2; i += 1) {
      options.push(
        React.createElement(Select.Option, { key: i, value: i, className: "year-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 306}}
          , i
        )
      );
    }
    return options;
  };

  const addEntry = (
    date,
    hours,
    comment,
    sick,
    vacation,
    project
  ) => {
    const timeObject = new Parse.Object("OD3_TimeTracking");
    let hoursClean = hours;
    try {
      hoursClean = parseFloat(hours.replace(/,/g, ".")) || hours;
    } catch (error) {
      // Keine Kommazahl
    }
    // Eigenschaften des Objekts setzen
    timeObject.set("Date", date);
    timeObject.set("WorkingHours", hoursClean);
    timeObject.set("Comment", comment);
    timeObject.set("Sick", sick);
    timeObject.set("Vacation", vacation);
    timeObject.set("Project", project);
    timeObject.set("User", currentUser);

    const acl = new Parse.ACL();
    const currentUserACL = currentUser;
    if (currentUserACL) {
      acl.setReadAccess(currentUserACL, true);
      acl.setWriteAccess(currentUserACL, true);
    }
    acl.setRoleReadAccess(
      "od-tenant-admin-" + _optionalChain([$parse, 'access', _14 => _14.user, 'access', _15 => _15.current, 'call', _16 => _16(), 'optionalAccess', _17 => _17.get, 'call', _18 => _18("tenant"), 'optionalAccess', _19 => _19.id]),
      true
    );
    acl.setRoleWriteAccess(
      "od-tenant-admin-" + _optionalChain([$parse, 'access', _20 => _20.user, 'access', _21 => _21.current, 'call', _22 => _22(), 'optionalAccess', _23 => _23.get, 'call', _24 => _24("tenant"), 'optionalAccess', _25 => _25.id]),
      true
    );

    timeObject.setACL(acl);
    timeObject.save().then(() => {
      getParseData();
      setHoursWorked(contractWorkingHours);
      calcTotalWorked();
      calcShouldWorked();
      setComment("");
      setSickLeaveChecked(false);
      setVacationChecked(false);
      setPercentage(percentage);
    });
  };

  const onPanelChange = (newValue) => {
    setValue(newValue);
    setSelectedMonth(newValue.month());
    setSelectedYear(newValue.year());
  };

  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().month(i).format("MMMM"));
  }

  const headerRender = ({
    value,
    onChange,
  }


) => {
    const month = value.month();
    const year = value.year();

    return (
      React.createElement('div', {
        style: {
          padding: "15px",
          width: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 388}}

        , React.createElement(Row, { gutter: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}}
          , !isMobile && (
            React.createElement(Col, { xs: { span: 24 }, sm: 20, __self: this, __source: {fileName: _jsxFileName, lineNumber: 396}}
              , React.createElement(Row, { gutter: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}}
                , React.createElement(Col, { xs: { span: 24 }, sm: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 398}}
                  , React.createElement(Tooltip, {
                    placement: "bottom",
                    title: 
                      totalWorked > shouldWorked
                        ? "Es sollten weniger Stunden gearbeitet werden, bitte über Gleitzeit überstunden ausgleichen."
                        : totalWorked === shouldWorked
                        ? "Monatstunden sind erreicht."
                        : ""
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}

                    , React.createElement(Statistic, {
                      valueStyle: {
                        textAlign: "center",
                        color:
                          totalWorked > shouldWorked
                            ? "red"
                            : totalWorked === shouldWorked
                            ? "green"
                            : "black",
                      },
                      style: { textAlign: "center" },
                      title: "Ist-Stunden im Monat"  ,
                      value: totalWorked.toFixed(2) + " Stunden", __self: this, __source: {fileName: _jsxFileName, lineNumber: 409}}
                    )
                  )
                )
                , React.createElement(Col, { xs: 24, sm: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 425}}
                  , React.createElement(Tooltip, {
                    placement: "bottom",
                    title: 
                      totalWorked > shouldWorked
                        ? "Es sollten weniger Stunden gearbeitet werden, bitte über Gleitzeit überstunden ausgleichen."
                        : totalWorked === shouldWorked
                        ? "Monatstunden sind erreicht."
                        : ""
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 426}}

                    , React.createElement(Statistic, {
                      valueStyle: {
                        textAlign: "center",
                        color: totalWorked === shouldWorked ? "green" : "black",
                      },
                      style: { textAlign: "center" },
                      title: "Soll-Stunden im Monat"  ,
                      value: shouldWorked.toFixed(2) + " Stunden",
                      precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 436}}
                    )
                  )
                )
                , props.researchprojectPlugin && (
                  React.createElement(Col, { xs: { span: 24 }, sm: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 449}}
                    , React.createElement(Tooltip, {
                      placement: "bottom",
                      title: 
                        totalProjectWorked > maxProjectWorked
                          ? "Für den Vertrag sind zuviele Projektstunden aufgeschrieben. Bitte reduzieren!"
                          : totalProjectWorked === maxProjectWorked
                          ? "Projektstunden in diesem Monat sind erreicht."
                          : ""
                      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 450}}

                      , React.createElement(Statistic, {
                        valueStyle: {
                          textAlign: "center",
                          color:
                            totalProjectWorked > maxProjectWorked
                              ? "red"
                              : totalProjectWorked === maxProjectWorked
                              ? "green"
                              : "black",
                        },
                        style: { textAlign: "center" },
                        title: "Projektstunden im Monat"  ,
                        value: totalProjectWorked.toFixed(2) + " Stunden",
                        precision: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 460}}
                      )
                    )
                  )
                )
              )
            )
          )
          , React.createElement(Col, {
            xs: { span: 24 },
            sm: 24,
            md: 24,
            lg: 4,
            style: {
              margin: "0px",
              padding: "0px",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 481}}

            , isMobile && (
              React.createElement(Row, {
                gutter: 24,
                style: {
                  textAlign: "center",
                  width: "100%",
                  margin: "0px",
                  padding: "0px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 492}}

                , React.createElement(Col, {
                  xs: { span: 24 },
                  sm: 24,
                  md: 24,
                  lg: 12,
                  style: {
                    width: "100%",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 501}}

                  , React.createElement(Select, {
                    size: "large",
                    style: { width: "100%", margin: "0px", padding: "0px" },
                    className: "my-year-select",
                    onChange: (newYear) => {
                      const now = value
                        .clone()
                        .year(parseInt(newYear, 10));
                      onChange(now);
                      setSelectedYear(parseInt(newYear, 10));
                    },
                    value: String(year), __self: this, __source: {fileName: _jsxFileName, lineNumber: 513}}

                    , getYearOptions()
                  )
                )
                , React.createElement(Col, {
                  xs: { span: 24 },
                  sm: 24,
                  md: 24,
                  lg: 12,
                  style: {
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 529}}

                  , React.createElement('div', { style: { width: "100%", height: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 540}})
                )
                , React.createElement(Col, {
                  xs: { span: 24 },
                  sm: 24,
                  md: 24,
                  lg: 12,
                  style: {
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 542}}

                  , React.createElement(Select, {
                    size: "large",
                    style: { width: "100%", margin: "0px", padding: "0px" },
                    value: String(months[month]),
                    onChange: (newMonth) => {
                      const now = value
                        .clone()
                        .month(parseInt(newMonth, 10));
                      onChange(now);
                      setSelectedMonth(parseInt(newMonth, 10));
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 553}}

                    , getMonthOptions()
                  )
                )
              )
            )
            , !isMobile && (
              React.createElement(Row, { gutter: 24, style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 571}}
                , React.createElement(Col, { xs: { span: 24 }, sm: 24, md: 24, lg: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 572}}
                  , React.createElement(Select, {
                    size: "large",
                    style: {
                      width: "100%",
                    },
                    className: "my-year-select",
                    onChange: (newYear) => {
                      const now = value
                        .clone()
                        .year(parseInt(newYear, 10));
                      onChange(now);
                      setSelectedYear(parseInt(newYear, 10));
                    },
                    value: String(year), __self: this, __source: {fileName: _jsxFileName, lineNumber: 573}}

                    , getYearOptions()
                  )
                )
                , React.createElement(Col, { xs: { span: 24 }, sm: 24, md: 24, lg: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 591}}
                  , React.createElement(Select, {
                    size: "large",
                    style: {
                      width: "100%",
                    },
                    value: String(months[month]),
                    onChange: (newMonth) => {
                      const now = value
                        .clone()
                        .month(parseInt(newMonth, 10));
                      onChange(now);
                      setSelectedMonth(parseInt(newMonth, 10));
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 592}}

                    , getMonthOptions()
                  )
                )
              )
            )
          )
        )
      )
    );
  };

  const getListData = (value) => {
    const listData = [];
    let filteredUserItems = [];
    const foundObjects = parseData.filter(
      (obj



) => dayjs(obj.get("Date")).isSame(value, "day")
    );

    if (foundObjects && foundObjects.length > 0) {
      let onlyUserItems = foundObjects.filter(
        (obj





) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _26 => _26.id])
      );
      if (onlyUserItems) {
        filteredUserItems = onlyUserItems;
      }
    }

    if (adminMode) {
      filteredUserItems = foundObjects;
    }

    filteredUserItems.forEach((element) => {
      const initials = element.get("User").get("Initial");
      const workingHoursOfDay = element.get("WorkingHours") + " Std.";
      let getProjectID = _optionalChain([projectMeta, 'optionalAccess', _27 => _27.find, 'call', _28 => _28((project) => {
        return _optionalChain([project, 'optionalAccess', _29 => _29.get, 'call', _30 => _30("Vertrag"), 'access', _31 => _31.id]) === _optionalChain([userMeta, 'optionalAccess', _32 => _32.id]);
      })]);

      const projectName = props.researchprojectPlugin
        ? _optionalChain([getProjectID, 'optionalAccess', _33 => _33.get, 'call', _34 => _34("Projekt"), 'access', _35 => _35.get, 'call', _36 => _36("Name")])
        : "Intern";

      const projectPercentageOfDay = props.researchprojectPlugin
        ? "(" +
          (!adminMode ? projectName : "Projektanteil") +
          ": " +
          element.get("Project") +
          "%)"
        : "";

      if (element.get("Sick")) {
        listData.push({
          type: "error",
          content: `${initials}: Krankheit`,
        });
      } else if (element.get("Vacation")) {
        listData.push({
          type: "warning",
          content: `${initials}: Urlaub`,
        });
      } else {
        listData.push({
          type: "success",
          content: `${initials}: ${workingHoursOfDay} ${projectPercentageOfDay}`,
        });
      }
    });

    return listData;
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const getMonthOptions = () => {
    const options = [];
    months.forEach((month, index) => {
      options.push(
        React.createElement(Select.Option, { key: index, value: index, className: "year-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 697}}
          , month
        )
      );
    });
    return options;
  };

  const handleModalOk = () => {
    if (validateInput()) {
      let workingHours = hoursWorked;
      let percentHours = percentage;
      if (sickLeaveChecked) {
        workingHours = 0;
        percentHours = 0;
      } else if (vacationChecked) {
        workingHours = 0;
        percentHours = 0;
      }

      addEntry(
        value.toDate(),
        workingHours || 0,
        comment,
        sickLeaveChecked,
        vacationChecked,
        percentHours
      );
      setModalVisible(false);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handlePercentageChange = (value) => {
    setPercentage(value);
  };

  const handleVacationChange = (e) => {
    setVacationChecked(e.target.checked);
  };

  const handleSickLeaveChange = (e) => {
    setSickLeaveChecked(e.target.checked);
  };

  const getModalTitle = () => {
    if (userMeta) {
      let getProjectID = _optionalChain([projectMeta, 'optionalAccess', _37 => _37.find, 'call', _38 => _38((project) => {
        return _optionalChain([project, 'optionalAccess', _39 => _39.get, 'call', _40 => _40("Vertrag"), 'optionalAccess', _41 => _41.id]) === _optionalChain([userMeta, 'optionalAccess', _42 => _42.id]);
      })]);
      return userMeta
        ? `${value.format("DD.MMMM.YYYY")}` +
            " | Projekt: " +
            (props.researchprojectPlugin
              ? _optionalChain([getProjectID, 'optionalAccess', _43 => _43.get, 'call', _44 => _44("Projekt"), 'access', _45 => _45.get, 'call', _46 => _46("Name")])
              : "Intern")
        : "";
    }
    return "";
  };

  const getModalEntry = () => {
    let foundObject = parseData.filter(
      (obj



) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    if (foundObject && foundObject.length > 0) {
      let allUserItems = foundObject;
      let onlyUserItems = foundObject.find(
        (obj) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _47 => _47.id])
      );
      if (onlyUserItems) {
        const filteredArray = allUserItems.filter(
          (item) => item !== onlyUserItems
        );
        return [onlyUserItems, adminMode ? filteredArray : []]; //HIER
      } else {
        return [false, adminMode ? allUserItems : []]; //HIER
      }
    } else {
      return [false, []];
    }
  };

  const checkDeleteStatus = (element) => {
    if (
      element.get("Vacation") &&
      !element.get("User").get("VacationWithoutApplication")
    ) {
      return false;
    }
    return (
      element.get("updatedAt") >
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000) ||
      _optionalChain([currentUser, 'optionalAccess', _48 => _48.get, 'call', _49 => _49("tenantAdmin")])
    );
  };

  const validateInput = () => {
    if (!/^(\d+([,.]\d{1,2})?|\d+)$/.test(`${hoursWorked || 0}`)) {
      message.error(
        "Ungültige Arbeitszeit. Bitte geben Sie die Arbeitsstunden im Format 'Stunden' ein (z.B. 8 oder 8.50)"
      );
      return false;
    }

    if (vacationChecked && sickLeaveChecked) {
      message.error(
        "Sie können nicht gleichzeitig Urlaub und Krankheit auswählen."
      );
      return false;
    }

    return true;
  };

  const getObjectForDate = (array, targetDate) => {
    const filteredObjects = array.filter((obj) => {
      return (
        obj.get("Date").getDate() === targetDate.getDate() &&
        obj.get("Date").getMonth() === targetDate.getMonth() &&
        obj.get("Date").getFullYear() === targetDate.getFullYear()
      );
    });

    return filteredObjects;
  };

  return (
    React.createElement('div', { style: { margin: "24px", width: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 839}}
      , React.createElement(AdminToolbar, {
        title: "Zeiterfassung",
        description: "Zeiterfassung für Mitarbeitende"  ,
        search: "",
        actions: 
          _optionalChain([$parse, 'access', _50 => _50.user, 'access', _51 => _51.current, 'call', _52 => _52(), 'optionalAccess', _53 => _53.get, 'call', _54 => _54("tenantAdmin")])
            ? [
                React.createElement(Button, {
                  key: "1",
                  onClick: (e) => {
                    let data = [
                      {
                        0: "Stundennachweise",
                        1: currentUser.get("name"),
                        2: "Jahr: " + selectedYear,
                        3:
                          "Monat: " +
                          (selectedMonth + 1).toString().padStart(2, "0"),
                      },
                      {},
                      {
                        0: "",
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        6: 6,
                        7: 7,
                        8: 8,
                        9: 9,
                        10: 10,
                        11: 11,
                        12: 12,
                        13: 13,
                        14: 14,
                        15: 15,
                        16: 16,
                        17: 17,
                        18: 18,
                        19: 19,
                        20: 20,
                        21: 21,
                        22: 22,
                        23: 23,
                        24: 24,
                        25: 25,
                        26: 26,
                        27: 27,
                        28: 28,
                        29: 29,
                        30: 30,
                        31: 31,
                      },
                    ];
                    const projectWork = {};
                    const otherWork = {};
                    const leaveWork = {};
                    const daysInMonth = new Date(
                      selectedYear,
                      selectedMonth + 1,
                      0
                    ).getDate();

                    let filteredData = [];
                    let foundObject = parseData.filter(
                      (obj



) =>
                        dayjs(obj.get("Date")).format("MM.YYYY") ===
                        dayjs(
                          `${selectedYear}-${(selectedMonth + 1)
                            .toString()
                            .padStart(2, "0")}`,
                          "YYYY-MM"
                        ).format("MM.YYYY")
                    );
                    if (foundObject && foundObject.length > 0) {
                      let onlyUserItems = foundObject.filter(
                        (obj





) => obj.get("User").id === _optionalChain([currentUser, 'optionalAccess', _55 => _55.id])
                      );
                      if (onlyUserItems && onlyUserItems.length > 0) {
                        filteredData = onlyUserItems;
                      }
                    }

                    leaveWork[0] = "Fehlzeiten";
                    projectWork[0] = "Projekt Stunden";
                    if (props.researchprojectPlugin) {
                      otherWork[0] = "Sonstige Stunden";
                    } else {
                      otherWork[0] = "Arbeitstunden";
                    }

                    for (let day = 1; day <= daysInMonth; day++) {
                      const dateKey = day + "";
                      let objectForDay = getObjectForDate(
                        filteredData,
                        new Date(selectedYear, selectedMonth, day)
                      );

                      if (objectForDay.length > 0) {
                        if (
                          objectForDay[0].get("Sick") ||
                          objectForDay[0].get("Vacation")
                        ) {
                          leaveWork[dateKey] = contractWorkingHours;
                          projectWork[dateKey] = 0;
                          otherWork[dateKey] = 0;
                        } else {
                          leaveWork[dateKey] = 0;

                          if (props.researchprojectPlugin) {
                            projectWork[dateKey] = (
                              (objectForDay[0].get("Project") *
                                objectForDay[0].get("WorkingHours")) /
                              100
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });

                            otherWork[dateKey] = (
                              Math.round(
                                (objectForDay[0].get("WorkingHours") -
                                  (objectForDay[0].get("Project") *
                                    objectForDay[0].get("WorkingHours")) /
                                    100) *
                                  100
                              ) / 100
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });
                          } else {
                            projectWork[dateKey] = (0).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });

                            otherWork[dateKey] = Math.round(
                              objectForDay[0].get("WorkingHours")
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });
                          }
                        }
                      } else {
                        leaveWork[dateKey] = 0;
                        projectWork[dateKey] = 0;
                        otherWork[dateKey] = 0;
                      }
                    }
                    if (props.researchprojectPlugin) {
                      data.push(projectWork);
                    }
                    data.push(otherWork);
                    data.push({});
                    data.push(leaveWork);
                    // Erstelle eine CSV-Datei
                    const csvContent =
                      "data:text/csv;charset=utf-8," +
                      data
                        .map((row) => Object.values(row).join(";"))
                        .join("\n");

                    // Erzeuge einen Download-Link
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute(
                      "download",
                      currentUser.get("Initial") +
                        "_Zeiten_" +
                        (selectedMonth + 1).toString().padStart(2, "0") +
                        "_" +
                        selectedYear +
                        ".csv"
                    );

                    // Füge den a-Tag zum Dokument hinzu und simuliere den Klick
                    document.body.appendChild(link);
                    link.click();

                    // Entferne den a-Tag
                    document.body.removeChild(link);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 847}}
, "Export"

                ),
                React.createElement(Select, {
                  key: "2",
                  defaultValue: _optionalChain([currentUser, 'optionalAccess', _56 => _56.get, 'call', _57 => _57("name")]),
                  style: { width: 120 },
                  onChange: (e) => {
                    if (e === 0) {
                      setAdminMode(true);
                      setCurrentUser(Parse.User.current());
                    } else {
                      setAdminMode(false);
                      setCurrentUser(userData[e]);
                    }
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1035}}

                  , userData.map((user, index) => (
                    React.createElement(Select.Option, { key: user.id, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1050}}
                      , user.get("name")
                    )
                  ))
                ),
              ]
            : []
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 840}}
      )
      , loading && (
        React.createElement('div', {
          className: "od-page-main",
          style: {
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1060}}

          , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 1070}} )
        )
      )
      , !loading && (
        React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1074}}
          , React.createElement(AlertView, {
            contract: userMeta ? true : false,
            isMobile: isMobile,
            totalWorked: totalWorked,
            shouldWorked: shouldWorked,
            totalProjectWorked: totalProjectWorked,
            maxProjectWorked: maxProjectWorked,
            selectedMonth: selectedMonth,
            selectedYear: selectedYear,
            daysEntered: daysEntered,
            anzahlWerktagen: anzahlWerktagen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1075}}
          )

          , React.createElement(CalendarView, {
            projectMeta: projectMeta,
            contract: userMeta ? true : false,
            isMobile: isMobile,
            value: value,
            headerRender: headerRender,
            onSelect: onSelect,
            onPanelChange: onPanelChange,
            selectedMonth: selectedMonth,
            selectedYear: selectedYear,
            getMonthData: getMonthData,
            getListData: getListData,
            freeDays: freeDays,
            setModalVisible: setModalVisible, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1088}}
          )

          , React.createElement(EntryModal, {
            props: props,
            currentUser: currentUser,
            modalTitle: getModalTitle(),
            modalVisible: modalVisible,
            vacationChecked: vacationChecked,
            handleVacationChange: handleVacationChange,
            hoursWorked: hoursWorked,
            handlePercentageChange: handlePercentageChange,
            percentage: percentage,
            handleCommentChange: handleCommentChange,
            comment: comment,
            sickLeaveChecked: sickLeaveChecked,
            handleSickLeaveChange: handleSickLeaveChange,
            informationText: informationText,
            handleModalOk: handleModalOk,
            handleModalCancel: handleModalCancel,
            getOwnList: getModalEntry()[0],
            checkDeleteStatus: checkDeleteStatus,
            deleteCurrentEntry: deleteCurrentEntry,
            setModalVisible: setModalVisible,
            getAllList: getModalEntry()[1],
            setHoursWorked: setHoursWorked, __self: this, __source: {fileName: _jsxFileName, lineNumber: 1104}}
          )
        )
      )
    )
  );
});
