const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/Pieces/ProjectRequest.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import dayjs from "dayjs";
import { Descriptions } from "antd";















export const ProjectRequest = ({
  personalKosten,
  otherKosten,
  materialKosten,
  tripKosten,
  rdKosten,
  investKosten,
  quarterList,
  currentProject,
  monthsInQuarter,
  selectedQuarter,
  overAllCosts,
}) => {
  const [descriptionProps, setDescriptionProps] = React.useState([]);

  const generateDescriptionItems = () => {
    let items = [
      {
        key: "1",
        labelStyle: { fontWeight: "bold", width: "50%" },
        contentStyle: { fontWeight: "bold", width: "50%" },
        label: "Position Gesamt-Vorkalkulation",
        children: (
          React.createElement(React.Fragment, null
            , !isNaN(Math.floor(_optionalChain([quarterList, 'optionalAccess', _ => _.getMonth, 'call', _2 => _2()]) / 3) + 1)
              ? "Q" + (Math.floor(_optionalChain([quarterList, 'optionalAccess', _3 => _3.getMonth, 'call', _4 => _4()]) / 3) + 1)
              : "..."
            , !isNaN(_optionalChain([quarterList, 'optionalAccess', _5 => _5.getFullYear, 'call', _6 => _6()]))
              ? "/" + _optionalChain([quarterList, 'optionalAccess', _7 => _7.getFullYear, 'call', _8 => _8()])
              : " ..."
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
              , monthsInQuarter.length > 0 && selectedQuarter != -1
                ? monthsInQuarter[selectedQuarter]
                    .map((dateString) => {
                      return dayjs(dateString, "MM.YYYY").format("MMMM YYYY");
                    })
                    .join(" | ")
                : "Keine Daten vorhanden"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "2",
        label: "0813 Material",
        children: (
          React.createElement(React.Fragment, null
            , materialKosten.length == 2
              ? materialKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " ", " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (materialKosten.length == 2
                  ? materialKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "3",
        label: "0823 FE-Fremdleistung",
        children: (
          React.createElement(React.Fragment, null
            , rdKosten.length == 2
              ? rdKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " ", " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (rdKosten.length == 2
                  ? rdKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "4",
        label: "0837 Personalkosten",
        children: (
          React.createElement(React.Fragment, null
            , personalKosten
              .reduce(
                (
                  accumulator,
                  currentValue
                ) => {
                  return accumulator + currentValue.totalCostsUntilQuarter;
                },
                0
              )
              .toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                personalKosten
                  .reduce(
                    (
                      accumulator,
                      currentValue




                    ) => {
                      return (
                        accumulator +
                        currentValue.workingHoursInOldQuarter *
                          currentValue.costRate *
                          (currentValue.overhead
                            ? 1 + _optionalChain([currentProject, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("Overhead")]) / 100
                            : 1)
                      );
                    },
                    0
                  )
                  .toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "5",
        label: "0838 Reisekosten",
        children: (
          React.createElement(React.Fragment, null
            , tripKosten.length == 2
              ? tripKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " ", " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (tripKosten.length == 2
                  ? tripKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "6",
        label: "0847 Abschreibungen",
        children: (
          React.createElement(React.Fragment, null
            , investKosten.length == 2
              ? investKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " ", " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (investKosten.length == 2
                  ? investKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "7",
        label: "0850 Sonstige Kosten",
        children: (
          React.createElement(React.Fragment, null
            , otherKosten
              .reduce(
                (
                  accumulator,
                  currentValue
                ) => {
                  return accumulator + currentValue.totalCostsUntilQuarter;
                },
                0
              )
              .toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 255}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                otherKosten
                  .reduce(
                    (
                      accumulator,
                      currentValue




                    ) => {
                      return (
                        accumulator +
                        currentValue.workingHoursInOldQuarter *
                          currentValue.costRate *
                          (currentValue.overhead
                            ? 1 + _optionalChain([currentProject, 'optionalAccess', _11 => _11.get, 'call', _12 => _12("Overhead")]) / 100
                            : 1)
                      );
                    },
                    0
                  )
                  .toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "8",
        labelStyle: { fontWeight: "bold" },
        contentStyle: { fontWeight: "bold" },
        label: "Summe",
        children: (
          React.createElement(React.Fragment, null
            , overAllCosts.length == 2
              ? overAllCosts[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " ", " ", "€"

            , React.createElement('div', { style: { fontSize: "12px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}, "Bisher in:"

              , " " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (overAllCosts.length == 2
                  ? overAllCosts[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"
            )
          )
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
    ];
    setDescriptionProps(items);
  };

  React.useEffect(() => {
    generateDescriptionItems();
  }, [
    personalKosten,
    otherKosten,
    materialKosten,
    tripKosten,
    rdKosten,
    investKosten,
    quarterList,
    currentProject,
    monthsInQuarter,
    selectedQuarter,
    overAllCosts,
  ]);

  return (
    React.createElement(Descriptions, {
      style: { width: "100%" },
      bordered: true,
      column: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
      items: descriptionProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
    )
  );
};
