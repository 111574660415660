const _jsxFileName = "/usr/src/app/src/js/components/researchprojects/src/components/AdminPages/ProjectContractComponent.tsx";import React from "react";

import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";



export const ProjectContractComponent = createComponent(
  function ProjectContractComponent() {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , React.createElement(ParseSetView, {
          className: "OD3_ProjectContract",
          view: 
            {
              type: "table",
              fields: ["Vertrag", "Projekt", "Monatsgehalt", "Projektanteil"],
            } 
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        )
      )
    );
  }
);
